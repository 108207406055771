<footer>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4">
                <div class="logo-footer"><img src="../../../assets/images/Logo bianco.png" alt="Logo Seven Live Web TV">
                </div>
            </div>
            <div class="col-lg-4">
                <div class="socials my-lg-0 my-4">
                    <div class="logo-social">
                        <a href="https://www.facebook.com/pages/Seven-Live-TV/108112955491?fref=ts" target="blank"
                            title="Facebook Seven Live Web TV">
                            <img src="../../../assets/images/footer/facebook.png" alt="Logo Facebook">
                        </a>
                    </div>
                    <div class="logo-social">
                        <a href="https://www.youtube.com/@sergiogiovanniceruttiSevenLive" target="blank"
                            title="Youtube Seven Live Web TV">
                            <img src="../../../assets/images/footer/youtube.png" alt="Logo YouTube">
                        </a>
                    </div>
                    <div class="logo-social">
                        <a href="https://www.instagram.com/sevenlive_webtv/" target="blank"
                            title="Instagram Seven Live Web TV">
                            <img src="../../../assets/images/footer/instagram.png" alt="Logo Instagram">
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 contacts">
                <p routerLink="/contattaci">CONTATTACI</p>
                <p routerLink="/condizioni">CONDIZIONI GENERALI di FORNITURA</p>
                <p routerLink="/privacy">INFORMATIVA e PRIVACY</p>
            </div>
        </div>
    </div>
</footer>
<div class="footer-placeholder"></div>