import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { Ticket } from 'src/app/shared/interfacce.model';
import { ChiudiTicketBackofficeComponent } from './chiudi-ticket-backoffice/chiudi-ticket-backoffice.component';
import { EliminaTicketBackofficeComponent } from './elimina-ticket-backoffice/elimina-ticket-backoffice.component';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-tickets-backoffice',
  templateUrl: './tickets-backoffice.component.html',
  styleUrls: ['./tickets-backoffice.component.css']
})
export class TicketsBackofficeComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router, private modal: NzModalService, private viewContainerRef: ViewContainerRef) { }

  tickets: Ticket[] = [];

  clienteFilter: any = '';
  statoFilter: any = '';

  ngOnInit(): void {

    // Recupero i tickets
    this.apiService.setTickets();
    this.apiService.ticketsChanged.subscribe({
      next: (data) => this.tickets = [...data],
      error: (err) => this.apiService.error(err.error.message),
      complete: () => { }
    });
  }

  onAdd() {
    this.router.navigate(['/backoffice/home/tickets/add']);
  }

  onEdit(id: number) {
    this.router.navigate(['/backoffice/home/tickets/' + id])
  }

  onElimina(id: number) {

    // Formo il modal per l'eliminazione del ticket
    this.modal.create({
      nzTitle: 'Conferma',
      nzContent: EliminaTicketBackofficeComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {
        id_ticket: id
      },
      nzFooter: null,
      nzCentered: true,
      nzWidth: 750,
      nzBodyStyle: { 'padding': '0px' }
    });
  }

  onClose(id: number) {

    // Formo il modal per la chiusura del ticket    
    this.modal.create({
      nzTitle: 'Conferma',
      nzContent: ChiudiTicketBackofficeComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {
        id_ticket: id
      },
      nzFooter: null,
      nzCentered: true,
      nzWidth: 750,
      nzBodyStyle: { 'padding': '0px' }
    });    
  }

  onOrdinamentoTitolo(a: Ticket, b: Ticket) {        
    return a.titolo.localeCompare(b.titolo);
  }

  onOrdinamentoCategoria(a: Ticket, b: Ticket) {    
    return a.categoria.localeCompare(b.categoria);
  }  

  onOrdinamentoCliente(a: Ticket, b: Ticket) {        
    return a.cliente.localeCompare(b.cliente);
  }

  onOrdinamentoStato(a: Ticket, b: Ticket) {        
    return a.stato.localeCompare(b.stato);
  }


}
