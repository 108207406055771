<nz-card [nzActions]="[actionClose, actionAdd]">
    <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" [ngStyle]="{ marginBottom: '12px' }" *ngIf="alertVisible"></nz-alert>
    <form [formGroup]="formCanale" (ngSubmit)="onSubmit()" id="formCanale">
        <div class="row">
            <div class="col">                                            
                <nz-form-item class="flex-column mb-3">
                    <nz-form-label nzRequired class="text-start">Nome</nz-form-label>
                    <nz-form-control>
                        <input nz-input nzSize="large" formControlName="nome" required />
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Descrizione</nz-form-label>
                    <nz-form-control>
                        <textarea nz-input nzSize="large" formControlName="descrizione"></textarea>
                    </nz-form-control>
                </nz-form-item>                        
            </div>
        </div>
        <div class="row mt-3">
            <div class="col text-center">
                <span nzTooltipTitle="Se selezionato, il canale verrà inserito in Home Page nella lista dei canli in evidenza!" nz-tooltip nzTooltipPlacement="top">                        
                    <label nz-checkbox formControlName="evidenza" class="checkbox">Canale in evidenza</label> 
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col text-center">
                <nz-tabset>
                    <nz-tab nzTitle="Icona">
                        <nz-upload [(nzFileList)]="fileIconaList" [nzShowButton]="fileIconaList.length <= 0" [nzBeforeUpload]="beforeIconaUpload" nzAccept="image/*" [nzRemove]="removeIconaUpload">
                            <button nzSize="large" nz-button type="button" class="home-button">
                                <span class="align-middle" nz-icon nzType="upload"></span>
                                Carica icona
                            </button>
                        </nz-upload>   
                        <img [src]="icona" alt="Icona" class="mt-3 w-100 text-center" *ngIf="icona.length > 0">
                    </nz-tab>
                    <nz-tab nzTitle="Copertina">
                        <nz-upload [(nzFileList)]="fileCopertinaList" [nzShowButton]="fileCopertinaList.length <= 0" [nzBeforeUpload]="beforeCopertinaUpload" nzAccept="image/*" [nzRemove]="removeCopertinaUpload">
                            <button nzSize="large" nz-button type="button" class="home-button">
                                <span class="align-middle" nz-icon nzType="upload"></span>
                                Carica copertina
                            </button>
                        </nz-upload>                            
                        <img [src]="copertina" alt="Copertina" class="mt-3 w-100 text-center" *ngIf="copertina.length > 0">
                    </nz-tab>                            
                </nz-tabset>
            </div>
        </div>   
    </form>      
</nz-card>    
<ng-template #actionClose>
    <button nz-button nzSize="large" type="button" (click)="onAnnulla()">Annulla</button>
</ng-template>
<ng-template #actionAdd>
    <button nz-button nzSize="large" type="submit" class="home-button" form="formCanale" [nzLoading]="loading">Aggiungi</button>                              
</ng-template>                            
