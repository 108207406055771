import { Component, OnInit, inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { ApiService } from 'src/app/shared/api.service';

@Component({
  selector: 'app-elimina-immaggini-canale',
  templateUrl: './elimina-immaggini-canale.component.html',
  styleUrls: ['./elimina-immaggini-canale.component.css']
})
export class EliminaImmagginiCanaleComponent implements OnInit {

  constructor(private apiService: ApiService) { }

  loading: boolean = false;  

  readonly #modal = inject(NzModalRef);
  readonly nzModalData = inject(NZ_MODAL_DATA);

  ngOnInit(): void {
  }

  onElimina() {
    this.loading = true;

    // Elimino il file (icona o immagine) dal canale
    this.apiService.deleteFile(this.nzModalData.tipo!, 'canale', this.nzModalData.nome_immagine!, "", this.nzModalData.id_canale!).subscribe({
      next: (data) => this.apiService.setCanali(),
      error: (err) => {
        this.loading = false;
        this.apiService.error(err.error.message);
      }, complete: () => {
        this.loading = false;
        this.#modal.destroy();
      }
    })

  }

  onClose() {
    this.#modal.destroy(false);    
  }

}
