<div class="container">

  <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" [ngStyle]="{ marginBottom: '12px' }"
    *ngIf="alertVisible"></nz-alert>

  <div class="annullato" *ngIf="!abbonamento">
    <h4 class="mb-4">Gestisci il tuo abbonamento</h4>
    <p>Il tuo abbonamento non è attivo. Attiva ora il tuo abbonamento ed entra a far parte di Seven Live Web TV!</p>
    <div class="mb-3 w-100 text-end">
      <button class="bottone" nzType="primary" nz-button [nzLoading]="loading" (click)="onAbbonamento()">ATTIVA
        L'ABBONAMENTO</button>
    </div>
  </div>

  <ng-container *ngIf="abbonamento">
    <div class="info">
      <h4 class="mb-4">Gestisci il tuo abbonamento</h4>
      <p>Stato abbonamento: <span class="badge status rounded-0"
          [ngClass]="{'bg-success': abbonamento.stato == 'attivo', 'bg-warning': abbonamento.stato == 'sospeso'}">{{abbonamento.stato | titlecase }}</span>
      </p>
      <p>
        {{abbonamento.stato === 'attivo' ? "L'abbonamento verrà rinnovato automaticamente il" :  "L'abbonamento verrà automaticamente annullato il" }}
        <strong>{{abbonamento.stripe_scadenza | date: 'dd/MM/yyyy'}}</strong> alle <strong>
          {{abbonamento.stripe_scadenza | date: 'HH:mm:ss'}}</strong> al prezzo di <span
          class="fw-semibold">{{abbonamento.prezzo | currency: 'EUR'}}</span></p>
      <div class="mb-3 w-100 text-end">
        <button class="bottone" nzType="primary" nz-button nzSize="large" [nzLoading]="loading"
          (click)="abbonamento.stato === 'attivo' ? onSospendi() : onAttivo()">{{abbonamento.stato === 'attivo' ? "SOSPENDI ABBONAMENTO" : "ATTIVA ABBONAMENTO"}}</button>
      </div>
    </div>

    <div class="rounded-2 border my-4"></div>

    <div class="tabella">
      <h4 class="mb-4">Storico pagamenti</h4>
      <p *ngIf="storico.length <= 0" class="text-center my-5 text-muted">Non hai associato nessun pagamento...</p>
      <nz-table #storicoTable *ngIf="storico.length > 0" [nzData]="storico" nzShowPagination
        [nzTotal]="(storico).length" [nzPageSize]="5" [nzPageSizeOptions]="[5, 10, 20, 30, 50, 100]" nzShowSizeChanger
        class="p-0">
        <thead>
          <tr>
            <th>Stato</th>
            <th>Importo</th>
            <th>Data</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of storicoTable.data">
            <td>{{ data.stato == 'paid' ? 'Pagato' : 'Annullato' }}</td>
            <td>{{ data.importo | currency: 'EUR' }}</td>
            <td>{{ data.data_creazione }}</td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </ng-container>
</div>