import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from 'src/app/shared/api.service';
import { Canale, Ondemand } from 'src/app/shared/interfacce.model';

@Component({
  selector: 'app-canale',
  templateUrl: './canale.component.html',
  styleUrls: ['./canale.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class CanaleComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute) { }

  canale?: Canale;
  ondemands: Ondemand[] = [];

  url: string = environment.apiUrl;
  copertina_url: string = '';

  ondemandLimit: number = 9;

  loading: boolean = true;

  ngOnInit(): void {

    // Recupero l'id passato nell'url del canale
    let id = +this.route.snapshot.paramMap.get('id')!;

    // Recupero i dati del canale
    this.apiService.getCanaleHome(id).subscribe({
      next: (data) => this.canale = data,
      error: (err) => {
        this.loading = false;
        this.apiService.error("<p><strong>Il canale non è stato caricato correttamente! </strong></p> <p>Ricaricare la pagina oppure aspettare qualche secondo. </p><p>In caso di errore continuo contattaci a <strong><a href='mailto:webtv@7live.tv'>webtv@7live.tv</a></strong>!</p>")
      },
      complete: () => {

        // Recupero la copertina del canale
        this.copertina_url =  this.canale?.copertina ? 'url("'  + this.url + 'immagine/' + this.canale?.copertina + '")' : "";

        // Recupero gli ondemand del canale
        this.apiService.getOndemandsCanale(id).subscribe({          
          next: (data) => this.ondemands = [...data.filter(ondemand => ondemand.cloudflare_mp4_url != null)],
          error: (err) => {
            this.loading = false;
            this.apiService.error("<p><strong>I video Ondemand non sono stati caricati correttamente! </strong></p> <p>Ricaricare la pagina oppure aspettare qualche secondo. </p><p>In caso di errore continuo contattaci a <strong><a href='mailto:webtv@7live.tv'>webtv@7live.tv</a></strong>!</p>")
          },
          complete: () => this.loading = false
        })

      }
    })
  }

  moreOndemands(increment: number) {
    this.ondemandLimit += increment;
  }

  onOndemand(id: number) {
    this.router.navigate(['/ondemand/' + btoa("id_ondemand=" + id)]);
  }

}
