<div class="p-3">
    <ng-template #ondemandTitle>        
        <div class="row p-md-3 p-1 align-items-center">
            <div class="col-md-8">
                <h3 class="text-white mb-0">On Demand</h3>
            </div>
            <div class="col-md-4">
                <button nz-button nzType="primary" nzSize="large" (click)="onAdd()" class="home-button w-100">Aggiungi On Demand</button>
            </div>
        </div>        
        <div class="row p-md-3 p-1">            
            <div class="col-md-6">                        
                <input nz-input placeholder="Cerca Titolo On Demand" nzSize="large" [(ngModel)]="titoloFilter" />
            </div>              
            <div class="col-md-6">                        
                <input nz-input placeholder="Cerca Canale On Demand" nzSize="large" [(ngModel)]="canaleFilter" />
            </div>                                   
        </div>
    </ng-template>
    <nz-card [nzTitle]="ondemandTitle">
        <div class="row p-md-3 p-1">
            <nz-table #ondemandTable nzShowPagination class="tabella"
                [nzTotal]="(ondemands | searchTitoloOndemand: titoloFilter | searchCanaleOndemand: canaleFilter).length"
                nzShowSizeChanger [nzPageSize]="10"
                [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
                [nzData]="ondemands | searchTitoloOndemand: titoloFilter | searchCanaleOndemand: canaleFilter"
                [nzBordered]="true" [nzNoResult]="'Nessun video On Demand trovato'">
                <thead>
                    <tr>
                        <th [nzSortFn]="onOrdinamentoTitolo">Titolo</th>
                        <th [nzSortFn]="onOrdinamentoCanale" class="text-start">Canale/i</th>                        
                        <th [nzSortFn]="onOrdinamentoCategoria" class="text-start">Categoria/e</th>                        
                        <th class="text-center">Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let ondemand of ondemandTable.data| searchTitoloOndemand: titoloFilter | searchCanaleOndemand: canaleFilter">
                        <td>{{ondemand.titolo}}</td>
                        <td class="text-start">{{ondemand.canale}}</td>                        
                        <td class="text-start">{{ondemand.categoria}}</td>                        
                        <td class="text-center">
                            <nz-button-group>                                
                                <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
                                    <span nz-icon nzType="ellipsis"></span>
                                </button>
                          </nz-button-group>
                          <nz-dropdown-menu #menu="nzDropdownMenu">
                            <ul nz-menu>
                              <li nz-menu-item class="p-3" (click)="onEdit(ondemand.id)">Modifica On Demand</li>                                                            
                              <li nz-menu-item class="p-3" (click)="onElimina(ondemand.id)">Elimina On Demand</li>
                            </ul>
                          </nz-dropdown-menu>
                        </td>
                    </tr>
                </tbody>
            </nz-table>            
        </div>
    </nz-card>
</div>