import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/shared/interfacce.model';
import { StorageService } from 'src/app/shared/storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isCollapsed: boolean = false;
  user?: User;

  constructor(private observer: BreakpointObserver, private storageService: StorageService, private router: Router, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.user = this.storageService.getUser();
    this.storageService.UserChanged.subscribe({
      next: (data) => this.user = this.storageService.getUser()
    })    
    
  }

  ngAfterViewInit() {

    this.observer.observe(['(max-width: 990px)']).subscribe((res) => {
      if (res.matches) {
        this.isCollapsed = true;
      } else {
        this.isCollapsed = false;
      }

      this.cdr.detectChanges();
    });
  }

  logout() {
    this.storageService.clearUser();
    this.router.navigate(['/home']);
  }
}
