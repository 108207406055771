import { Component, OnInit, HostListener, ViewChild, ElementRef, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { style, animate, transition, trigger } from '@angular/animations';
import { ApiService } from 'src/app/shared/api.service';
import { Ondemand } from 'src/app/shared/interfacce.model';
import Hls from "hls.js";

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(250, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(250, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class PlayerComponent implements OnInit {

  @ViewChild('videoPlayer', { static: true }) videoPlayer?: ElementRef;
  video?: HTMLVideoElement;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {

    let ricerca = (<HTMLInputElement>document.getElementById('queryFiltroVideo'));

    if (event.key == ' ') {
      if (event.srcElement != ricerca) {
        event.preventDefault();
        this.onTogglePlay();
      }
    }
    if (event.key === 'ArrowRight') {
      this.onSkip(15, undefined)
    }
    if (event.key === 'ArrowLeft') {
      this.onSkip(-15, undefined)
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseStop(e: MouseEvent) {

    let doc: any = window.document;
    this.isFScreen = !doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement ? false : true;

    if (this.isFScreen) {

      clearTimeout(this.timer);

      let cursor = document.body.style.cursor;
      let video: any = document.getElementById('video');

      if (cursor == 'none') {
        document.body.style.cursor = "auto";
        let controlli = document.getElementById('controls');
        controlli?.classList.remove('fullscreen');
      }

      this.timer = setTimeout(function () {

        if (cursor == '' || cursor == 'auto') {
          document.body.style.cursor = "none";
          let controlli = document.getElementById('controls');
          controlli?.classList.add('fullscreen');
        }

      }, 3000);
    } else {
      let cursor = document.body.style.cursor;

      if (cursor == 'none') {

        document.body.style.cursor = "auto";
        let controlli = document.getElementById('controls');
        controlli?.classList.remove('fullscreen');
      }
    }
  }

  constructor(private apiService: ApiService, private route: ActivatedRoute, private deviceService: DeviceDetectorService) { }

  currentVideo: { src: string, poster: string, titolo: string, canale: string, descrizione: string, categoria: string, data: Date } = { src: '', descrizione: '', poster: '', titolo: '', canale: '', categoria: '', data: new Date() };

  tempoUpd: any;
  tempo: number = 0;
  durata: number = 0;
  volume: number = 100;
  timer: any = 0;
  isFScreen: boolean = false;

  completion: number = 0;

  source: any;

  isMobile: boolean = false;
  isApple: boolean = false;

  loading: boolean = true;

  //Intervallo
  seeking: boolean = false;

  changingVolume: boolean = false;

  url: string = environment.apiUrl;
  hls: any = undefined;

  qualitySelector: boolean = false;
  qualityToggleAnimation: boolean = false;
  qualitaDisponibili: any[] = [];

  ondemand?: Ondemand;

  ngOnInit(): void {
    
    // Recupero i parametri passati nell'url
    this.route.params.subscribe(params => {

      const string = params['id'];      
      const id = atob(string).split('id_ondemand=')[1];      
      
      if (id) {
        // Recupero i dati del video ondemand passato
        this.apiService.getOndemandHome(+id).subscribe({
          next: (data) => this.ondemand = data,
          error: () => this.apiService.error("<p><strong>Il video Ondemand non è stato caricato correttamente! </strong></p> <p>Ricaricare la pagina oppure aspettare qualche secondo. </p><p>In caso di errore continuo contattaci a <strong><a href='mailto:webtv@7live.tv'>webtv@7live.tv</a></strong>!</p>"),
          complete: () => {
            this.getDataVideo(this.ondemand!)
          }
        })   
      }   
    })
        

  }

  getDataVideo(ondemand: Ondemand) {

    // Azzero il tempo
    this.tempo = 0;
    this.completion = 0;

    // Recupero il tipo di dispositivo
    this.isMobile = this.deviceService.isMobile();
    this.isApple = this.deviceService.os == 'iOS';

    // Recupero l'elemento html del video
    let video = document.querySelector('video');
    if (video) { video.setAttribute("controlsList", "nodownload"); }

    this.video = document.getElementById('video') as HTMLVideoElement;
    this.source = document.getElementById('videoSource') as HTMLElement;

    // Recupero le informazioni e le imposto per il live in esposizione    
    this.currentVideo = { descrizione: ondemand.descrizione, src: ondemand.cloudflare_video_url, poster: ondemand.cloudflare_image_url, titolo: ondemand.titolo, canale: ondemand.canale, categoria: ondemand.categoria, data: ondemand.data_aggiornamento };
    this.video.src = this.currentVideo.src;
    this.source.src = this.currentVideo.src;

    this.loading = false;

    // Se l'HLS è supportato procedo
    if (Hls.isSupported()) {

      // Creo una nuova connessione e carico il video 
      this.hls = new Hls();
      this.hls.loadSource(this.currentVideo.src);
      this.hls.attachMedia(this.video);

    } else if (this.video?.canPlayType('application/vnd.apple.mpegurl')) {
      this.source = document.getElementById('videoSource') as HTMLElement;
      this.source.src = this.currentVideo.src;
    }

    // Carico il video
    this.video.load();

    // Setto durata, volume per il player
    this.video.onloadedmetadata = (e: any) => {
      let video = e.target
      this.durata = video.duration * 1000;
      this.volume = video.volume * 100;
      e.src = this.currentVideo.src
      this.tempo = 0;

      this.qualitaDisponibili = this.hls.levels;
    }

    // Se il video è finito, recupero gli altri live
    this.tempoUpd = setInterval(() => {
      if (!this.video?.paused) {
        this.tempo = this.video!.currentTime * 1000;
        this.completion = this.video!.currentTime / this.video!.duration;
      }
    }, 10);

  }

  onTogglePlay() {
    if (this.isApple) return;

    if (this.video!.paused)
      this.video!.play();
    else
      this.video!.pause();
  }

  onMute() {
    this.video!.muted = !this.video?.muted;
  }

  onFullScreen() {

    let doc: any = window.document;
    let docEl: any = document.getElementById('player');

    let requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
    let cancelFullScreen = doc.exitFullscreen || doc?.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

    if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
      requestFullScreen.call(docEl);
      this.isFScreen = true;
    }
    else {
      cancelFullScreen.call(doc);
      this.isFScreen = false;
    }
  }

  onSeek(event: any) {
    if (this.seeking) {
      this.completion = event.offsetX / event.target.offsetParent.offsetWidth;
      this.video!.currentTime = this.video!.duration * this.completion;
    }
  }

  onRightClick(e: any) {
    return false;
  }

  onVolume(event: any) {

    if (this.changingVolume) {
      let volume = event.offsetX / event.target.offsetParent.offsetWidth;
      this.video!.volume = volume;
    }

    // this.video!.volume == 0 ? this.video!.muted = true : this.video!.muted = false;
  }

  onSkip(amount: number, e: any) {
    this.video!.currentTime += amount;
    if (amount > 0 && e) {
      e.target.classList.add('spinR');
      setTimeout(() => {
        e.target.classList.remove('spinR');
      }, 500);
    } else {
      e.target.classList.add('spinL');
      setTimeout(() => {
        e.target.classList.remove('spinL');
      }, 500);
    }
  }

  onPause($e: any) {
    /* alert($e.target.id + '-' + $e.target.classList) */
    if ($e.target.id == 'controls') {
      this.onTogglePlay();
    }
  }

  onQuality() {
    this.qualityToggleAnimation = true;
    setTimeout(() => {
      this.qualityToggleAnimation = false;
    }, 500)
  }
}
