import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { Live, UserBackoffice } from 'src/app/shared/interfacce.model';
import { EliminaLiveBackofficeComponent } from './elimina-live-backoffice/elimina-live-backoffice.component';
import { StorageService } from 'src/app/shared/storage.service';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-live-backoffice',
  templateUrl: './live-backoffice.component.html',
  styleUrls: ['./live-backoffice.component.css']
})
export class LiveBackofficeComponent implements OnInit {

  user?: UserBackoffice;

  live: Live[] = [];

  titoloFilter: any = '';
  canaleFilter: any = '';  

  constructor(private apiService: ApiService, private storageService: StorageService, private router: Router, private modal: NzModalService, private viewContainerRef: ViewContainerRef) { }

  ngOnInit(): void {

    // Recupero l'utente loggato nel backoffice
    this.user = this.storageService.getUserBackoffice();

    // Recupero le live
    this.apiService.setLive();
    this.apiService.liveChanged.subscribe({
      next: (data) => {
        
        // Se l'utente loggato è di tipo amministratore recupero tutte le live altrimenti solo quelle del canale associato
        if (this.user?.tipo == 'Amministratore')
          this.live = [...data];
        else
          this.live = [...data.filter((live: Live) => live.id_canale == this.user?.id_canale)];
      },
      error: (err) => this.apiService.error(err.error.message),
      complete: () => { }
    })

  }

  onAdd() {
    this.router.navigate(['/backoffice/home/live/add']);
  }  

  onEdit(id: number) {
    this.router.navigate(['/backoffice/home/live/' + id])
  }

  onElimina(id: number) {

    // Creo il modal per l'eliminazione del live
    this.modal.create({
      nzTitle: 'Conferma',
      nzContent: EliminaLiveBackofficeComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {
        id_live: id
      },
      nzFooter: null,
      nzCentered: true,
      nzWidth: 750,
      nzBodyStyle: { 'padding': '0px' }
    });
  }

  onOrdinamentoTitolo(a: Live, b: Live) {
    return a.titolo.localeCompare(b.titolo)
  }

  onOrdinamentoCanale(a: Live, b: Live) {
    return a.canale.localeCompare(b.canale)
  }

}
