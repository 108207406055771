import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.css']
})
export class CookiesComponent implements OnInit {

  constructor() { }

  cookies: boolean = false;
  dismissed: boolean = false;

  ngOnInit(): void {
    localStorage.getItem("cookies") ? this.cookies = true : this.cookies = false;
  }

  dismiss(){
    this.dismissed = true;
  }

  accept(type: string){
    localStorage.setItem('cookies', type);
    this.dismiss();
  }

  decline(){
    localStorage.removeItem('cookies');
    this.dismiss();
  }

}
