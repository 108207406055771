<div class="container pb-5">

  <div class="d-flex justify-content-center py-md-3 py-5">
    <img class="d-lg-none d-block" src="../../../assets/images/Logo bianco.png" height="40px" alt="">
  </div>
  
  <div class="row">
    <h3 class="text-white display-5 fw-bold">News</h3>
  </div>

  <div class="row" *ngIf="loading">
    <div class="col text-center">
      <app-video-loading></app-video-loading>
    </div>
  </div>

  <div class="row" *ngIf="notizie.length <= 0 && !loading">
    <h3 class="mt-3 d-flex justify-content-center align-items-center">Nessuna news caricata</h3>
  </div>

  <app-notizia *ngFor="let notizia of notizie; let i = index" [notizia]="notizia" [notizie]="notizie" [index]="i"></app-notizia>

</div>