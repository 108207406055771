<div class="p-3">
    <ng-template #newsTitle>        
        <div class="row p-md-3 p-1 align-items-center">
            <div class="col-md-8">
                <h3 class="text-white mb-0">News</h3>
            </div>
            <div class="col-md-4">
                <button nz-button nzType="primary" nzSize="large" (click)="onAdd()" class="home-button w-100">Aggiungi news</button>
            </div>
        </div>        
        <div class="row p-md-3 p-1">            
            <div class="col-md-6">                        
                <input nz-input placeholder="Cerca Titolo news" nzSize="large" [(ngModel)]="titoloFilter" />
            </div>              
            <div class="col-md-6">                        
                <input nz-input placeholder="Cerca Data news" nzSize="large" [(ngModel)]="dataFilter" />                
            </div>                                   
        </div>
    </ng-template>
    <nz-card [nzTitle]="newsTitle">
        <div class="row p-md-3 p-1">
            <nz-table #newsTable nzShowPagination class="tabella"
                [nzTotal]="(notizie | searchTitoloNews: titoloFilter | searchDataNews: dataFilter).length"
                nzShowSizeChanger [nzPageSize]="10"
                [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
                [nzData]="notizie | searchTitoloNews: titoloFilter | searchDataNews: dataFilter"
                [nzBordered]="true" [nzNoResult]="'Nessuna news trovata'">
                <thead>
                    <tr>
                        <th [nzSortFn]="onOrdinamentoTitolo">Titolo</th>
                        <th [nzSortFn]="onOrdinamentoData" class="text-start">Data</th>                                                
                        <th class="text-center">Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let notizia of newsTable.data| searchTitoloNews: titoloFilter | searchDataNews: dataFilter">
                        <td>{{notizia.titolo}}</td>
                        <td class="text-start">{{notizia.data_creazione | date: 'dd/MM/YYYY'}}</td>                                                
                        <td class="text-center">
                            <nz-button-group>                                
                                <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
                                    <span nz-icon nzType="ellipsis"></span>
                                </button>
                          </nz-button-group>
                          <nz-dropdown-menu #menu="nzDropdownMenu">
                            <ul nz-menu>
                              <li nz-menu-item class="p-3" (click)="onEdit(notizia.id)">Modifica news</li>                              
                              <li nz-menu-item class="p-3" (click)="onEliminaImmagine(notizia.copertina, notizia.id)">Elimina copertina news</li>
                              <li nz-menu-item class="p-3" (click)="onElimina(notizia.id)">Elimina news</li>
                            </ul>
                          </nz-dropdown-menu>
                        </td>
                    </tr>
                </tbody>
            </nz-table>            
        </div>
    </nz-card>
</div>