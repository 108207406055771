<div class="p-3">
    <ng-template #utentiTitle>
        <h3 class="text-white mb-0">Aggiungi Utente</h3>
    </ng-template>    
    <nz-card [nzTitle]="utentiTitle" [nzActions]="[actionClose, actionAdd]" class="mt-5 mb-5" >
        <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" [ngStyle]="{ marginBottom: '12px' }" *ngIf="alertVisible"></nz-alert>
        <form [formGroup]="formUtente" (ngSubmit)="onSubmit()" id="formUtente">                        
            <div class="row">
                <div class="col-md-6">                    
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Username</nz-form-label>
                        <nz-form-control>
                            <input nz-input nzSize="large" formControlName="username" required />                 
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div class="col-md-6">
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Password</nz-form-label>
                        <nz-form-control>
                            <nz-input-group [nzSuffix]="passwordTemplate" nzSize="large">
                                <input [type]="hide ? 'text' : 'password'" nz-input formControlName="password" required />
                            </nz-input-group>
                            <ng-template #passwordTemplate>
                                <span nz-icon [nzType]="hide ? 'eye-invisible' : 'eye'" (click)="hide = !hide"></span>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>            
            <div class="row">
                <div [ngClass]="{'col-md-6': formUtente.get('tipo')?.value == 'Operatore', 'col': formUtente.get('tipo')?.value != 'Operatore'}">                    
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Tipo</nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearch nzAllowClear formControlName="tipo" nzSize="large" class="w-100" (ngModelChange)="onTipo($event)" required>
                                <nz-option nzValue="Amministratore" nzLabel="Amministratore"></nz-option>
                                <nz-option nzValue="Operatore" nzLabel="Operatore"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div class="col-md-6" *ngIf="formUtente.get('tipo')?.value == 'Operatore'">                    
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Canale</nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearch nzAllowClear formControlName="canale" nzSize="large" class="w-100" (ngModelChange)="onTipo($event)" required>
                                <nz-option *ngFor="let canale of canali" [nzValue]="canale.id" [nzLabel]="canale.nome"></nz-option>                        
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>                    
                </div>
            </div>                            
        </form>
    </nz-card>    

    <ng-template #actionClose>
        <button nz-button nzSize="large" type="button" (click)="onAnnulla()">Annulla</button>        
    </ng-template>
    <ng-template #actionAdd>
        <button nz-button nzSize="large" [nzLoading]="loading" type="submit" class="home-button" form="formUtente">Aggiungi</button>        
    </ng-template>
    
</div>