<!-- <div class="play d-flex align-items-center flex-column" *ngIf="!loading" [@fadeInOut]>
  <div class="spinner-border text-light" role="status">
      <span class="sr-only"></span>
  </div>
</div> -->
<div id="player" [class.hover]="video.paused" class="player">  
  <h3 class="mb-0 p-0 ms-4 mt-4 text-white testo" *ngIf="isApple">{{currentVideo.titolo}}</h3>
  <!-- controlli video -->
  <div class="controls" id="controls" (click)="onPause($event)" (dblclick)="onFullScreen()" [class.hover]="video.paused" *ngIf="!isApple" (mouseleave)="seeking = false">        
    <div class="title d-flex justify-content-between align-items-center">
      <h3 class="mb-0 ms-3 mt-2 text-white testo crop w-75" [attr.title]="currentVideo.titolo">{{currentVideo.titolo}}      
      </h3>        
      <i class="fs-1 bi bi-x" routerLink="/ondemand"></i>
    </div>    
    <div class="play d-flex align-items-center"></div>
    <div class="fondo">
      <div class="d-flex align-items-center position-relative time-container" (mouseup)="seeking = false; video.play();" (mousedown)="seeking = true; onSeek($event); video.pause();" (mousemove)="onSeek($event)">        
        <div class="progress" [style.width]="completion * 100 + '%'"></div>        
        <div class="back"></div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="sx d-flex align-items-center">
          <div class="me-2">
            <i class="me-3 fs-2 bi" [class.bi-play-fill]="video.paused" [class.bi-pause-fill]="!video.paused" (click)="onTogglePlay()"></i>            
          </div>
          <div class="stamp" style="font-size: 92.5%;">
            <span>{{ (tempo >= 3600000) ? (tempo | date: 'hh:mm:ss':'UTC') : (tempo | date: 'mm:ss')}} / {{(durata >= 3600000) ? (durata | date: 'hh:mm:ss':'GMT') : (durata | date: 'mm:ss')}}</span>
          </div>
          <div class="volume d-flex align-items-center">
            <span class="ms-3 me-2"><i class="bi fs-3" (click)="onMute()" [class.bi-volume-mute-fill]="video.muted" [class.bi-volume-off-fill]="!video.muted && video.volume <= 0.3" [class.bi-volume-down-fill]="!video.muted && video.volume > 0.3 && video.volume <= 0.6" [class.bi-volume-up-fill]="!video.muted && video.volume > 0.6" ></i></span>
            <div class="volume_wrapper cursor-pointer d-flex align-items-center position-relative" style="width: 100px" (mouseleave)="changingVolume = false" (mouseup)="changingVolume = false;" (mousedown)="changingVolume = true; onVolume($event);" (mousemove)="onVolume($event)">              
              <div class="progress" [style.width]="video.volume * 100 + '%'"></div>
              <div class="back"></div>
            </div>
          </div>
        </div>
        <div class="dx d-flex align-items-center">
          <div class="tasti d-flex align-items-center">
            <div [@fadeInOut] *ngIf="qualitySelector" class="qualita gap-2 cursor-pointer">
              <span [class.selezionato]="hls.autoLevelEnabled" (click)="hls.currentLevel = -1;">Auto</span>
              <span [class.selezionato]="hls.currentLevel == i && !hls.autoLevelEnabled" (click)="hls.currentLevel = i" *ngFor="let l of qualitaDisponibili; let i = index">{{l.height}}p</span>
            </div>
            <span class="ms-3 me-1" (click)="qualitySelector = !qualitySelector; onQuality();" *ngIf="!isMobile">
              <div class="fs-4" [class.spin]="qualityToggleAnimation">
                <i class="bi bi-gear-fill"></i>
              </div>
            </span>
            <span class="ms-3 me-1">
              <i class="bi fs-4" [ngClass]="{'bi-fullscreen':!isFScreen,'bi-fullscreen-exit':isFScreen}" (click)="onFullScreen()"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <video #video class="live-full" id="video" playsinline [controls]="!isFScreen && isApple" [autoplay]="false" [poster]="currentVideo.poster" (click)="onTogglePlay()" (contextmenu)="onRightClick($event)">
    <source id="videoSource" type="video/mp4" />
  </video>
</div>
<p style="padding: 12px;" class="text-muted text-break" *ngIf="currentVideo.descrizione">{{currentVideo.data | date: 'dd/MM/YYYY'}} - {{currentVideo.descrizione}}</p>