import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/api.service';
import { News } from 'src/app/shared/interfacce.model';


@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  notizie: News[] = [];  

  loading: boolean = false;

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.loading = true;

    this.apiService.getNewsHome().subscribe({
      next: (data) => this.notizie = data,
      error: (err) => this.apiService.error("<p><strong>Le news non sono state recuperate correttamente! </strong></p> <p>Ricaricare la pagina oppure aspettare qualche secondo. </p><p>In caso di errore continuo contattaci a <strong><a href='mailto:webtv@7live.tv'>webtv@7live.tv</a></strong>!</p>"),
      complete: () => this.loading = false
    })
  }

  onExpand(news: News) {
    news.espandi = !news.espandi;
  }

}
