import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-canali-live',
  templateUrl: './canali-live.component.html',
  styleUrls: ['./canali-live.component.css']
})
export class CanaliLiveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }

}
