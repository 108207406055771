import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardBackOffice  {

  constructor(private router: Router, private api: ApiService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return new Observable<boolean>(obs => {
      
      this.api.autorizzazioneBackoffice().subscribe({
        next: (data) => {
          if (!data) {
            this.router.navigateByUrl("/backoffice");
            return obs.next(false);
          }
          else {
            return obs.next(true);
          }
        },
        error: (err) => this.router.navigateByUrl("/backoffice"),
        complete: () => { }
      })
    });
  }
}