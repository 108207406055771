import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { News } from 'src/app/shared/interfacce.model';
import { EliminaNewsBackofficeComponent } from './elimina-news-backoffice/elimina-news-backoffice.component';
import { EliminaImmagginiNewsComponent } from './elimina-immaggini-news/elimina-immaggini-news.component';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-news-backoffice',
  templateUrl: './news-backoffice.component.html',
  styleUrls: ['./news-backoffice.component.css']
})
export class NewsBackofficeComponent implements OnInit {
  
  notizie: News[] = [];    

  titoloFilter: any = '';
  dataFilter: any = '';

  constructor(private apiService: ApiService, private router: Router, private modal: NzModalService, private viewContainerRef: ViewContainerRef) { }

  ngOnInit(): void {
    
    // Recupero le news
    this.apiService.setNews();
    this.apiService.newsChanged.subscribe({
      next: (data) => this.notizie = [...data],
      error: (err) => this.apiService.error(err.error.message),
      complete: () => { }      
    });
  }

  onAdd() {
    this.router.navigate(['/backoffice/home/news/add']);
  }

  onEdit(id: number) {
    this.router.navigate(['/backoffice/home/news/' + id])
  }

  onElimina(id: number) {        
    // Formo il modal per l'eliminazione della news
    this.modal.create({
      nzTitle: 'Conferma',
      nzContent: EliminaNewsBackofficeComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {
        id_news: id
      },
      nzFooter: null,
      nzCentered: true,
      nzWidth: 750,
      nzBodyStyle: {'padding': '0px'}
    });
  }

  onEliminaImmagine(copertina: string, id: number) {
    // Formo il modal per l'eliminazione dell'immagine della news
    this.modal.create({
      nzTitle: 'Conferma',
      nzContent: EliminaImmagginiNewsComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {        
        nome_immagine: copertina,
        id_news: id
      },
      nzFooter: null,
      nzCentered: true,
      nzWidth: 750,
      nzBodyStyle: {'padding': '0px'}
    });    
  }

  onOrdinamentoTitolo(a: News, b: News) {        
    return a.titolo.localeCompare(b.titolo);
  }

  onOrdinamentoData(a: News, b: News) {    
    return a.data_creazione.toString().localeCompare(b.data_creazione.toString());
  }  

}
