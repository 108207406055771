import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/shared/api.service';

@Component({
  selector: 'app-registrazione',
  templateUrl: './registrazione.component.html',
  styleUrls: ['./registrazione.component.css']
})
export class RegistrazioneComponent implements OnInit {

  constructor(private apiService: ApiService, private datePipe: DatePipe, private router: Router, private modalService: NzModalService) { }

  formRegistrazione!: FormGroup;

  hide: boolean = false;
  hide_confirm: boolean = false;

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  isVisible: boolean = false;

  url: string = environment.webtvUrl;

  ngOnInit(): void {

    this.formRegistrazione = new FormGroup({
      email: new FormControl(null),
      password: new FormControl(null),
      confirm_password: new FormControl(null),
      nome: new FormControl(null),
      cognome: new FormControl(null),
      data_nascita: new FormControl(null),
      luogo_nascita: new FormControl(null),
      codice_fiscale: new FormControl(null),
      telefono: new FormControl(null),
      newsletter: new FormControl(null),
      privacy: new FormControl(null)
    });
  }

  onError(message: string) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = 'error';
    this.loading = false;
  }

  onRegistrazione() {
    this.loading = true;

    let cliente = {
      email: this.formRegistrazione.get('email')?.value,
      password: this.formRegistrazione.get('password')?.value,
      confirm_password: this.formRegistrazione.get('confirm_password')?.value,
      nome: this.formRegistrazione.get('nome')?.value,
      cognome: this.formRegistrazione.get('cognome')?.value,
      data_nascita: this.datePipe.transform(this.formRegistrazione.get('data_nascita')?.value, 'yyyy-MM-dd'),
      luogo_nascita: this.formRegistrazione.get('luogo_nascita')?.value,
      codice_fiscale: this.formRegistrazione.get('codice_fiscale')?.value,
      telefono: this.formRegistrazione.get('telefono')?.value,
      newsletter: this.formRegistrazione.get('newsletter')?.value || false,
      privacy: this.formRegistrazione.get('privacy')?.value
    }

    this.apiService.registrazione(cliente).subscribe({
      next: (data) => { },
      error: (err) => this.onError(err.error.message),
      complete: () => {
        this.loading = false;

        this.modalService.success({
          nzTitle: 'Esito',
          nzContent: "<br> Grazie per esserti registrato a Seven Live Web TV. <br><br> Per completare la registrazione conferma il tuo indirizzo email andando a completare i passaggi che ti sono stati inviati all'email inserita.",
          nzClosable: false,
          nzOkText: 'Vai al login',
          nzCentered: true,
          nzOnOk: () => this.router.navigate(['/login'])
        });
      }

    })

  }


}
