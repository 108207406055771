import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/shared/api.service';
import { Ticket, User } from 'src/app/shared/interfacce.model';
import { StorageService } from 'src/app/shared/storage.service';

@Component({
  selector: 'app-ticket-profilo',
  templateUrl: './ticket-profilo.component.html',
  styleUrls: ['./ticket-profilo.component.css']
})
export class TicketProfiloComponent implements OnInit {

  constructor(private apiService: ApiService, private storageService: StorageService) { }

  user?: User;

  tickets: Ticket[] = [];

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  formTicket!: FormGroup;

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formTicket = new FormGroup({
      categoria: new FormControl(null),
      titolo: new FormControl(null),
      messaggio: new FormControl(null),
    })

    // Recupero l'utente loggato
    this.user = this.storageService.getUser();

    // Recupero i ticket
    this.apiService.setTicketsCliente(this.user.id);
    this.apiService.ticketsClienteChanged.subscribe({
      next: (data) => this.tickets = data,
      error: (err) => this.onError(err.error.message, "error"),
      complete: () => { }      
    })
  }

  onError(message: string, type: "success" | "info" | "warning" | "error") {    
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = type;
    this.loading = false;
  }

  onSubmit() {
    // Formo l'oggetto per la richiesta per l'api
    const ticket = {
      categoria: this.formTicket.value.categoria,
      titolo: this.formTicket.value.titolo,
      messaggio: this.formTicket.value.messaggio,
      id_cliente: this.user!.id,
      home: true
    }

    this.loading = true;

    // Aggiungo il ticket
    this.apiService.addTicket(ticket).subscribe({
      next: (data) => { },
      error: (err) => {
        this.onError(err.error.message, "error");
      },
      complete: () => {        
        this.onError('Ticket salvato con successo!', "success");
        this.apiService.setTicketsCliente(this.user!.id)
      }
    })
  }
}


