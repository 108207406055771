<div class="row">
    <div class="col-lg-8 pe-lg-0">        
        <div id="player-twitch" class="player d-flex" style="position: relative; z-index: 1; height: 100%;">                        
            <div class="controls" id="controls" *ngIf="controlli" [@fadeInOut] (click)="triggerPlay()" (dblclick)="onFullScreen()">
                <div class="row">
                    <div class="col">                                    
                        <h3 class="mb-0 ms-3 mt-2 text-white testo crop" [attr.title]="currentVideo.titolo">{{currentVideo.titolo}}</h3>
                    </div>
                </div>
                <div class="fondo">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="sx d-flex align-items-center">
                            <div class="stamp">
                                <span class="badge bg-danger">LIVE</span>
                            </div>
                            <div class="d-flex align-items-center ms-3">
                                <i class="bi fs-4" [class.bi-play-fill]="player.isPaused()" [class.bi-pause-fill]="player.isPaused() == false" (click)="togglePause()"></i>
                            </div>
                            <div class="volume d-flex align-items-center">
                                <span class="ms-3 me-2"><i class="bi fs-4" (click)="onMute()" [class.bi-volume-mute-fill]="player.muted" [class.bi-volume-up-fill]="!player.muted"></i></span>
                                <div class="volume_wrapper d-flex align-items-center position-relative" style="width: 100px">
                                    <input class="volume_slider" type="range" min="0" max="100" (input)="onVolume($event)" (change)="onVolume($event)" [value]="volume">
                                    <div class="progress" [style.width]="volume + '%'"></div>
                                    <div class="back"></div>
                                </div>
                            </div>
                        </div>
                        <div class="dx d-flex align-items-center">
                            <div class="tasti d-flex align-items-center">
                                <span class="ms-3 me-1"><i class="bi fs-5" [ngClass]="{'bi-fullscreen':!isFScreen,'bi-fullscreen-exit':isFScreen}" (click)="onFullScreen()"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="twitch-player_{{id}}" class="w-100"></div>
        </div>                                                      
    </div>
    <div class="col-lg-4 ps-0 lista d-none d-lg-block">
        <ul class="player-list">
            <p>In diretta ora:</p>
            <li *ngFor="let live of lives;" [ngClass]="{'selected': live.selected}">
                <div class="row">
                    <div class="col-md-4">
                        <img #icona [src]="live.icona ? url + 'immagine/' + live.icona : '../../../assets/images/Logo bianco.png'" (error)="icona.src = '../../../assets/images/Logo bianco.png'" class="watermark" alt="Icona">
                    </div>
                    <div class="col-md-8 my-auto" [attr.title]="live.titolo">
                        {{ live.titolo }}
                    </div>
                </div>
            </li>

            <li *ngIf="lives.length <= 0">Nessun live presente</li>
        </ul>
    </div>
</div>
<div class="row d-block d-lg-none">
    <div class="col">
        <div class="lives-list" *ngIf="lives.length > 0">
            <div class="playerlist-icon" *ngFor="let live of lives" (click)="onVideoChange(live)" [ngClass]="{'selected-mobile': live.selected}">
                <img #icona_mobile [src]="live.icona ? url + 'immagine/' + live.icona : '../../../assets/images/Logo bianco.png'" (error)="icona_mobile.src = '../../../assets/images/Logo bianco.png'" class="watermark" alt="Icona">
            </div>
        </div>
    </div>
</div>