import { Component, OnInit, inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { ApiService } from 'src/app/shared/api.service';

@Component({
  selector: 'app-elimina-categoria-backoffice',
  templateUrl: './elimina-categoria-backoffice.component.html',
  styleUrls: ['./elimina-categoria-backoffice.component.css']
})
export class EliminaCategoriaBackofficeComponent implements OnInit {

  constructor(private apiService: ApiService) { }

  loading: boolean = false;  

  readonly #modal = inject(NzModalRef);
  readonly nzModalData = inject(NZ_MODAL_DATA);

  ngOnInit(): void {
  }

  onElimina() {

    this.loading = true;

    // Elimino la categoria
    this.apiService.deleteCategoria(this.nzModalData.id_categoria!).subscribe({
      next: (data) => this.apiService.setCategorie(),
      error: (err) => {
        this.loading = false;
        this.apiService.error(err.error.message);
      },
      complete: () => {
        this.loading = false;
        this.#modal.destroy();
      }
    })

  }

  onClose() {
    this.#modal.destroy();
  }

}
