<div class="p-3">
    <ng-template #canaleTitle>
        <h3 class="text-white mb-0">Modifica On Demand</h3>
    </ng-template>
    <nz-card [nzTitle]="canaleTitle" [nzActions]="[actionClose, actionEdit]" class="mt-5 mb-5">
        <nz-alert nzType="error" nzShowIcon
            nzMessage="Per ragioni di compatibilità, assicurati che i video siano in formato mp4 (h264/aac)"
            [ngStyle]="{ marginBottom: '12px' }" [nzIcon]="iconAlert"></nz-alert>
        <ng-template #iconAlert><span nz-icon nzType="warning" nzTheme="outline"></span></ng-template>
        <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" [ngStyle]="{ marginBottom: '12px' }"
            *ngIf="alertVisible"></nz-alert>

        <form [formGroup]="formOndemand" (ngSubmit)="onSubmit()" id="formOndemand">
            <div class="row">
                <div class="col">
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Titolo</nz-form-label>
                        <nz-form-control>
                            <input nz-input nzSize="large" formControlName="titolo" required/>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label class="text-start">Descrizione</nz-form-label>
                        <nz-form-control>
                            <textarea nz-input nzSize="large" formControlName="descrizione"></textarea>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Canale</nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearch nzAllowClear formControlName="id_canale" nzMode="multiple" nzSize="large" class="w-100" required>
                                <nz-option *ngFor="let canale of canali" [nzValue]="canale.id" [nzLabel]="canale.nome"></nz-option>                                
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div class="col-md-6">
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Categoria</nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearch nzAllowClear formControlName="id_categoria" nzMode="multiple" nzSize="large" class="w-100" required>
                                <nz-option *ngFor="let categoria of categorie" [nzValue]="categoria.id" [nzLabel]="categoria.titolo"></nz-option>                                
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col text-center">
                    <span nzTooltipTitle="Se selezionato, il video On Demand sarà disponibile alla visione solo tramite l'abbonamento!" nz-tooltip nzTooltipPlacement="top">                        
                        <label nz-checkbox formControlName="abbonamento" class="checkbox">Visione tramite abbonamento</label> 
                    </span>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col text-center">
                    <nz-tabset>
                        <nz-tab nzTitle="* Video">

                            <div class="row">
                                <div class="my-auto text-center col">                                    
                                    <uppy-dashboard class="my-uppy-dashboard" [uppy]="uppy" [props]="{inline: true, theme: 'dark', width: '100%', height: 350, proudlyDisplayPoweredByUppy: false, hideUploadButton: true}" ></uppy-dashboard>                                          
                                </div>                                
                            </div>  

                            <div class="row mt-3">                                
                                <div class="col">
                                    <button nz-button nzSize="large" type="button" class="home-button" (click)="visible = !visible">{{visible ? 'Nascondi video caricato' : 'Mostra video caricato' }}</button>
                                </div>
                            </div>                                                                   

                            <div class="row mt-3" *ngIf="visible">
                                <div class="col">
                                    <video [src]="ondemand?.cloudflare_mp4_url" [poster]="ondemand?.cloudflare_image_url" class="w-100" style="aspect-ratio: 16/9;" [controls]="true" [autoplay]="false"></video>                                    
                                </div>
                            </div>                            

                        </nz-tab>
                        <nz-tab nzTitle="* Copertina">
                            <div class="row">
                                <div class="my-auto col">
                                    <nz-upload [(nzFileList)]="imageList" [nzShowButton]="imageList.length <= 0" [nzBeforeUpload]="beforeImageUpload" nzAccept="image/*" [nzRemove]="removeImageUpload">
                                        <button nzSize="large" nz-button type="button" class="home-button">
                                            <span class="align-middle" nz-icon nzType="upload"></span>
                                            Carica copertina
                                        </button>
                                    </nz-upload>
                                </div>                                
                            </div>    

                            <div class="row" *ngIf="ondemand?.copertina || copertina">
                                <div class="col">
                                    <img #img [src]="copertina.length > 0 ? copertina : copertina_url" (error)="img.src = ondemand!.cloudflare_image_url"  alt="Copertina" class="mt-4 w-100">
                                </div>
                            </div>                            
                        </nz-tab>
                    </nz-tabset>
                </div>
            </div>            
        </form>
    </nz-card>

    <ng-template #actionClose>
        <button nz-button nzSize="large" type="button" (click)="onClose()">Annulla</button>
    </ng-template>
    <ng-template #actionEdit>
        <button nz-button nzSize="large" [nzLoading]="loading" type="submit" class="home-button" form="formOndemand">Modifica</button>
    </ng-template>
</div>