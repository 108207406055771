import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserBackoffice } from 'src/app/shared/interfacce.model';
import { StorageService } from 'src/app/shared/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header-backoffice',
  templateUrl: './header-backoffice.component.html',
  styleUrls: ['./header-backoffice.component.css']
})
export class HeaderBackofficeComponent implements OnInit {

  user?: UserBackoffice;

  collapse: boolean = false;

  url: string = environment.webtvUrl;

  constructor(private observer: BreakpointObserver, private router: Router, private storageService: StorageService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {

    // Recupero l'utente loggato nel backoffice
    this.user = this.storageService.getUserBackoffice();
  }

  onLogout() {
    // Rimuovo i dati salvati nello storage
    this.storageService.clearUserBackoffice();
    this.router.navigate(['/backoffice'])
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // Se cambia la grandezza dello schermo tolgo sidebar
      this.observer.observe(['(max-width: 1024px)']).subscribe((res) => {
        if (res.matches) {
          this.collapse = true;
        } else {
          this.collapse = false
        }

        this.cdr.detectChanges();

      });      

    }, 0);
  }

}
