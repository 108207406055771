import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/shared/interfacce.model';
import { StorageService } from 'src/app/shared/storage.service';

@Component({
  selector: 'app-nessun-abbonamento',
  templateUrl: './nessun-abbonamento.component.html',
  styleUrls: ['./nessun-abbonamento.component.css']
})
export class NessunAbbonamentoComponent implements OnInit {

  constructor(private storageService: StorageService) { }

  user?: User;
  
  ngOnInit(): void {

    this.user = this.storageService.getUser();
    
  }

}
