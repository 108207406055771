import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/shared/interfacce.model';
import { StorageService } from 'src/app/shared/storage.service';

@Component({
  selector: 'app-profilo',
  templateUrl: './profilo.component.html',
  styleUrls: ['./profilo.component.css']
})
export class ProfiloComponent implements OnInit {

  constructor(private storageService: StorageService, private router: Router) { }

  user?: User;

  ngOnInit(): void {
    this.user = this.storageService.getUser();
  }

  onLogout() {
    this.storageService.clearUser();
    this.router.navigate(['/login'])
  }

}
