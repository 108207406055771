<div class="p-3">
    <ng-template #clienteTitle>        
        <div class="row p-md-3 p-1 align-items-center">
            <div class="col-md-8">
                <h3 class="text-white mb-0">Clienti</h3>
            </div>
            <div class="col-md-4">
                <button nz-button nzType="primary" nzSize="large" (click)="onAdd()" class="home-button w-100">Aggiungi cliente</button>
            </div>
        </div>        
        <div class="row p-md-3 p-1">            
            <div class="col-md-6">                        
                <input nz-input placeholder="Cerca Cognome o Nome cliente" nzSize="large" [(ngModel)]="clienteFilter" />
            </div>              
            <div class="col-md-6">                        
                <input nz-input placeholder="Cerca Email cliente" nzSize="large" [(ngModel)]="emailFilter" />
            </div>                                   
        </div>
    </ng-template>
    <nz-card [nzTitle]="clienteTitle">
        <div class="row p-md-3 p-1">
            <nz-table #clienteTable nzShowPagination class="tabella"
                [nzTotal]="(clienti | searchNomeCognomeCliente: clienteFilter | searchEmailCliente: emailFilter).length"
                nzShowSizeChanger [nzPageSize]="10"
                [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
                [nzData]="clienti | searchNomeCognomeCliente: clienteFilter | searchEmailCliente: emailFilter"
                [nzBordered]="true" [nzNoResult]="'Nessun cliente trovato'">
                <thead>
                    <tr>
                        <th [nzSortFn]="onOrdinamentoCliente">Cognome e Nome</th>
                        <th [nzSortFn]="onOrdinamentoEmail" class="text-start">Email</th>                        
                        <th [nzSortFn]="onOrdinamentoStato" class="text-center">Stato</th>                        
                        <th class="text-center">Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let cliente of clienteTable.data| searchNomeCognomeCliente: clienteFilter | searchEmailCliente: emailFilter">
                        <td>{{cliente.cognome}} {{cliente.nome}}</td>
                        <td class="text-start">{{cliente.email}}</td>                        
                        <td class="text-center">{{cliente.stato}}</td>                        
                        <td class="text-center">
                            <nz-button-group>                                
                                <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
                                    <span nz-icon nzType="ellipsis"></span>
                                </button>
                          </nz-button-group>
                          <nz-dropdown-menu #menu="nzDropdownMenu">
                            <ul nz-menu>
                              <li nz-menu-item class="p-3" (click)="onEdit(cliente.id)">Modifica cliente</li>                              
                              <li nz-menu-item class="p-3" (click)="onEditPassword(cliente.id)">Modifica password cliente</li>                              
                              <li nz-menu-item class="p-3" (click)="onConferma(cliente.id)" *ngIf="cliente.stato != 'attivo'">Re-invia conferma email</li>                              
                              <li nz-menu-item class="p-3" (click)="onElimina(cliente.id)">Elimina cliente</li>
                            </ul>
                          </nz-dropdown-menu>
                        </td>
                    </tr>
                </tbody>
            </nz-table>            
        </div>
    </nz-card>
</div>