<div class="p-3">
    <ng-template #utentiTitle>        
        <div class="row p-md-3 p-1 align-items-center">
            <div class="col-md-8">
                <h3 class="text-white mb-0">Utenti</h3>
            </div>
            <div class="col-md-4">
                <button nz-button nzType="primary" nzSize="large" (click)="onAdd()" class="home-button w-100">Aggiungi utente</button>
            </div>
        </div>        
        <div class="row p-md-3 p-1">            
            <div class="col-md-6">                        
                <input nz-input placeholder="Cerca Username utente" nzSize="large" [(ngModel)]="usernameFilter" />
            </div>              
            <div class="col-md-6">                        
                <input nz-input placeholder="Cerca Tipo utente" nzSize="large" [(ngModel)]="tipoFilter" />                
            </div>                                   
        </div>
    </ng-template>
    <nz-card [nzTitle]="utentiTitle">
        <div class="row p-md-3 p-1">
            <nz-table #utentiTable nzShowPagination class="tabella"
                [nzTotal]="(utenti | searchUsernameUtente: usernameFilter | searchTipoUtente: tipoFilter).length"
                nzShowSizeChanger [nzPageSize]="10"
                [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
                [nzData]="utenti | searchUsernameUtente: usernameFilter | searchTipoUtente: tipoFilter"
                [nzBordered]="true" [nzNoResult]="'Nessun utente trovato'">
                <thead>
                    <tr>
                        <th [nzSortFn]="onOrdinamentoUsername">Username</th>
                        <th [nzSortFn]="onOrdinamentoTipo" class="text-start">Tipo</th>                                                
                        <th class="text-center">Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let utente of utentiTable.data | searchUsernameUtente: usernameFilter | searchTipoUtente: tipoFilter">
                        <td>{{utente.username}}</td>
                        <td class="text-start">{{utente.tipo}}</td>                                                
                        <td class="text-center">
                            <nz-button-group>                                
                                <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
                                    <span nz-icon nzType="ellipsis"></span>
                                </button>
                          </nz-button-group>
                          <nz-dropdown-menu #menu="nzDropdownMenu">
                            <ul nz-menu>
                              <li nz-menu-item class="p-3" (click)="onEdit(utente.id)">Modifica utente</li>                                                            
                              <li nz-menu-item class="p-3" (click)="onElimina(utente.id)">Elimina utente</li>
                            </ul>
                          </nz-dropdown-menu>
                        </td>
                    </tr>
                </tbody>
            </nz-table>            
        </div>
    </nz-card>
</div>