import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ApiService } from 'src/app/shared/api.service';
import { Abbonamento, Storico, User } from 'src/app/shared/interfacce.model';
import { StorageService } from 'src/app/shared/storage.service';

@Component({
  selector: 'app-abbonamenti-profilo',
  templateUrl: './abbonamenti-profilo.component.html',
  styleUrls: ['./abbonamenti-profilo.component.css']
})
export class AbbonamentiProfiloComponent implements OnInit {

  abbonamento?: Abbonamento | null;
  storico: Storico[] = [];
  check?: { status: string, stripe_scadenza: number };

  user!: User;

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  constructor(private apiService: ApiService, private storageService: StorageService, private modalService: NzModalService) { }

  ngOnInit(): void {
    this.getData(true);
  }

  getData(scadenza?: boolean) {

    this.user = this.storageService.getUser();

    // Recupero l'abbonamento se ne ha uno
    this.apiService.getAbbonamentoCliente(this.user.id).subscribe({
      next: (data) => this.abbonamento = data,
      error: (err) => this.onError(err.error.message),
      complete: () => {

        // Recupero lo storico dei pagamenti del cliente
        this.apiService.getStoricoPagamentiCliente(this.abbonamento!.stripe_id).subscribe({
          next: (data) => this.storico = [...data],
          error: (err) => this.onError(err.error.message),
          complete: () => {

            if (scadenza)
              this.getScadenza();
          }
        })

      }
    })


  }

  getScadenza() {

    // controllo scadenza abbonamento
    const scadenza = new Date(this.abbonamento!.stripe_scadenza).getTime() - new Date().getTime();

    // Controllo stato abbonameto su stripe
    this.apiService.checkAbbonamento(this.abbonamento!.stripe_sub).subscribe({
      next: (data) => this.check = data,
      error: (err) => this.onError(err.error.message),
      complete: () => {

        // Se l'abbonamento non è attivo lo rimuovo
        if (this.check?.status != 'active') {

          // Rimuovo l'abbonamento
          this.apiService.rimozioneAbbonamento(this.abbonamento!.stripe_sub, this.abbonamento!.id).subscribe({
            next: (data) => { },
            error: (err) => this.onError(err.error.message),
            complete: () => this.getData(true)
          })

        } else {

          // Se la scadenza è minore = 0 aggiorno la data di scadenza sul db
          if (scadenza <= 0) {
            // Aggiorno la data di scadenza 
            this.apiService.updateAbbonamento(this.abbonamento!.id, this.check?.stripe_scadenza).subscribe({
              next: (data) => { },
              error: (err) => this.onError(err.error.message),
              complete: () => this.getData(true)
            })
          }

        }
      }
    })
  }

  onSospendi() {
    // Creo il modal per la conferma di sospensione dell'abbonamento
    this.modalService.confirm({
      nzTitle: 'Vuoi sospendere il tuo abbonamento?',
      nzContent: 'Per sospendere il tuo abbonamento premi sul pulsante qua sottostante. Potrai ancora riattivare il tuo abbonamento fino alla data di scadenza.',
      nzOkText: 'Sospendi',
      nzCentered: true,
      nzOnOk: () =>
        // Se ha confermato sospendo l'abbonamento
        this.apiService.sospendiAbbonamento(this.abbonamento!.stripe_sub).subscribe({
          next: (data) => { },
          error: (err) => this.onError(err.error.message),
          complete: () => this.getData(true)
        })
    });
  }

  onAttivo() {
    // Creo il modal per l'attivazione dell'abbonamento
    this.modalService.confirm({
      nzTitle: 'Vuoi ri-attivare il tuo abbonamento?',
      nzContent: 'Per attivare il tuo abbonamento premi sul pulsante qua sottostante. Potrai ancora sospendere il tuo abbonamento successivamente.',
      nzOkText: 'Attiva',
      nzCentered: true,
      nzOnOk: () =>
        // Se ha confermato attivo l'abbonamento
        this.apiService.attivaAbbonamento(this.abbonamento!.stripe_sub, this.abbonamento!.id).subscribe({
          next: (data) => { },
          error: (err) => this.onError(err.error.message),
          complete: () => this.getData(true)
        })
    });
  }

  onAbbonamento() {
    // Recupero il link di stripe per l'abbonamento e lo apro nel browser
    this.apiService.getPagamento(this.user.id).subscribe({
      next: (data) => window.location.href = data.url,
      error: (err) => this.onError(err.error.message),
      complete: () => { }
    })
  }

  onError(message: string) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = 'error';
    this.loading = false;
  }
}
