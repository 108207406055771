<div class="p-3">
    <ng-template #newsTitle>
        <h3 class="text-white mb-0">Aggiungi News</h3>
    </ng-template>    
    <nz-card [nzTitle]="newsTitle" [nzActions]="[actionClose, actionAdd]" class="mt-5 mb-5">
        <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" [ngStyle]="{ marginBottom: '12px' }" *ngIf="alertVisible"></nz-alert>
        <form [formGroup]="formNews" (ngSubmit)="onSubmit()" id="formNews">                        
            <div class="row">
                <div class="col-md-6">
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Titolo</nz-form-label>
                        <nz-form-control>
                            <input nz-input nzSize="large" formControlName="titolo" required />
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div class="col-md-6">
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Data</nz-form-label>
                        <nz-form-control>
                            <nz-date-picker formControlName="data" [nzMode]="'date'" nzSize="large" [nzFormat]="'dd/MM/yyyy'" class="w-100"></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>                    
                </div>
            </div>            
            <div class="row">
                <div class="col">
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Descrizione</nz-form-label>
                        <nz-form-control>
                            <textarea nz-input nzSize="large" formControlName="descrizione" required></textarea>
                        </nz-form-control>
                    </nz-form-item>                    
                </div>
            </div>            
            <div class="row">
                <div class="col">                    
                    <nz-upload [(nzFileList)]="imageList" [nzShowButton]="imageList.length <= 0" [nzBeforeUpload]="beforeImageUpload" nzAccept="image/*" [nzRemove]="removeImageUpload" class="w-100">
                        <button nzSize="large" nz-button type="button" class="home-button">
                          <span class="align-middle" nz-icon nzType="upload"></span>
                          Carica immagine di copertina
                        </button>
                    </nz-upload>                            
    
                    <img [src]="copertina" alt="Copertina" class="mt-3 w-100" *ngIf="copertina.length > 0">                    
                </div>
            </div>          
        </form>
    </nz-card>    

    <ng-template #actionClose>
        <button nz-button nzSize="large" type="button" (click)="onClose()">Annulla</button>        
    </ng-template>
    <ng-template #actionAdd>
        <button nz-button nzSize="large" [nzLoading]="loading" type="submit" class="home-button" form="formNews">Aggiungi</button>        
    </ng-template>
    
</div>