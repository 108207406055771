<div class="bg-light registrazione d-flex align-items-center">
    <div class="container-fluid">
        <div class="row">  
            <div class="col-lg-6 d-none d-lg-flex bg-image"></div>      
            <div class="col-lg-6 my-auto">
                <div class="form mx-auto p-lg-5 py-5 px-4">

                    <h3 class="display-4 fw-bold">Registrati</h3>
                    <p class="text-muted mb-4">Registrati a Seven Live Web TV per poter accedere ai nostri fantastici video!
                    </p>

                    <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" [ngStyle]="{ marginBottom: '12px' }" *ngIf="alertVisible"></nz-alert>

                    <form [formGroup]="formRegistrazione" (ngSubmit)="onRegistrazione()">
                        <div class="form-data">
                            <div class="row">
                                <div class="col">
                                    <div class="forms-inputs mb-4"> <span>* Email</span> <input nz-input placeholder="email@email.com" type="text" type="email" formControlName="email"></div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="forms-inputs mb-4"><span>* Password</span>
                                        <nz-input-group [nzSuffix]="suffixTemplate">
                                            <input [type]="hide ? 'text' : 'password'" nz-input formControlName="password" placeholder="Inserisci la tua password" />
                                        </nz-input-group>
                                        <ng-template #suffixTemplate> <span nz-icon [nzType]="hide ? 'eye-invisible' : 'eye'" (click)="hide = !hide"></span></ng-template>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="forms-inputs mb-4"> <span>* Conferma Password</span>
                                        <nz-input-group [nzSuffix]="suffixTemplateConfirm">
                                            <input [type]="hide_confirm ? 'text' : 'password'" nz-input formControlName="confirm_password" placeholder="Conferma la tua password" />
                                        </nz-input-group>
                                        <ng-template #suffixTemplateConfirm> <span nz-icon [nzType]="hide_confirm ? 'eye-invisible' : 'eye'" (click)="hide_confirm = !hide_confirm"></span></ng-template>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="forms-inputs mb-4"> <span>* Cognome</span> <input nz-input placeholder="Rossi" formControlName="cognome"></div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="forms-inputs mb-4"> <span>* Nome</span> <input nz-input placeholder="Mario" formControlName="nome"></div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="forms-inputs mb-4"> <span>* Data di nascita</span>
                                        <nz-date-picker name="data_nascita" formControlName="data_nascita" nzFormat="dd/MM/yyyy"></nz-date-picker>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="forms-inputs mb-4"> <span>Luogo di nascita <i>(opzionale)</i></span> 
                                        <input nz-input placeholder="Torino" formControlName="luogo_nascita">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="forms-inputs mb-4"> <span>Codice fiscale <i>(opzionale)</i></span> 
                                        <input nz-input formControlName="codice_fiscale">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="forms-inputs mb-4"> <span>Telefono <i>(opzionale)</i></span> 
                                        <input nz-input formControlName="telefono">
                                    </div>
                                </div>
                            </div>
                            <div class="checkboxes">
                                <div class="checkbox-container">                                            
                                    <label nz-checkbox formControlName="newsletter" class="checkbox">Accetto di ricevere mail dalla NewsLetter</label> 
                                </div>
                                <div class="checkbox-container">                                            
                                    <label nz-checkbox formControlName="privacy" class="checkbox">Accetto i termini e uso della <strong routerLink="/privacy" style="cursor: pointer; text-decoration: underline;">Privacy & Policy</strong> di Seven Live Web TV</label> 
                                </div>
                            </div>
                            <div class="mt-5 mb-3 text-center">
                                <button class="bottone" nz-button type="submit" nzSize="large" [disabled]="!formRegistrazione.valid" [nzLoading]="loading">Registrati</button>
                                <nz-divider class="text-black" nzText="oppure"></nz-divider>
                                <button class="bottone-secondario" nz-button type="button" routerLink="/login">Accedi</button>
                            </div>
                        </div>
                    </form>                                    

                </div>
            </div>

        </div>
    </div>
</div>