import { Component, Input, OnInit } from '@angular/core';
import { News } from 'src/app/shared/interfacce.model';

@Component({
  selector: 'app-notizia',
  templateUrl: './notizia.component.html',
  styleUrls: ['./notizia.component.css']
})
export class NotiziaComponent implements OnInit {

  constructor() { }

  @Input() notizia!: News
  @Input() index!: number;
  @Input() notizie: News[] = []

  ngOnInit(): void {
  }

  onExpand(news: News){
    news.espandi = !news.espandi;
  }
}
