<img class="bg" #img (error)="img.src = ondemand.cloudflare_image_url" alt="">
<div class="overlay" (mouseenter)="onMouse(true)" (mouseleave)="onMouse(false)">
    <span class="durata">{{getTime(ondemand.durata)}}</span>
    <div class="free-badge" *ngIf="!ondemand.abbonamento">
        <span>Video gratuito!</span>
        <i class="bi bi-unlock-fill"></i>
    </div>
    <div class="testi d-inline-block">
        <div class="vrappatore">
            <div class="descrizione">
                <h5 id="titolo" class="p-0 m-0 text-white testo crop" [attr.title]="ondemand.titolo">{{ondemand.titolo}}</h5>
                <p id="desc" class="p-0 m-0 text-white testo crop">{{ondemand.descrizione}}</p>
            </div>
        </div>
    </div>
</div>