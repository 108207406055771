<div class="container">

  <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" [ngStyle]="{ marginBottom: '12px' }"
    *ngIf="alertVisible"></nz-alert>

  <h4 class="mb-4">C'è qualche problema? Faccelo sapere</h4>

  <form [formGroup]="formTicket" (ngSubmit)="onSubmit()">
    <div class="card">
      <div class="form-data">
        <div class="row">
          <div class="col-lg-6">
            <div class="forms-inputs mb-3">
              <span>* Categoria</span><br>
              <nz-select formControlName="categoria" [nzSize]="'large'" class="w-100" nzPlaceHolder="Categorie">
                <nz-option nzLabel="Profilo" nzValue="Profilo"></nz-option>
                <nz-option nzLabel="Abbonamento" nzValue="Abbonamento"></nz-option>
                <nz-option nzLabel="Live" nzValue="Live"></nz-option>
                <nz-option nzLabel="On Demand" nzValue="On Demand"></nz-option>
                <nz-option nzLabel="Contatti" nzValue="Contatti"></nz-option>
                <nz-option nzLabel="Altro" nzValue="Altro"></nz-option>
              </nz-select>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="forms-inputs mb-3">
              <span>* Titolo</span>
              <input nz-input placeholder="Titolo" formControlName="titolo">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="forms-inputs mb-3">
              <span>* Messaggio</span>
              <textarea nz-input placeholder="Messaggio" formControlName="messaggio"></textarea>
            </div>
          </div>
        </div>
        <div class="mb-3 text-end">
          <button class="bottone" nzType="primary" nz-button type="submit" nzSize="large" [nzLoading]="loading" [disabled]="!formTicket.valid">INVIA</button>
        </div>
      </div>
    </div>
  </form>


  <div class="rounded-2 border my-4"></div>

  <div class="tabella">    
    <h4 class="mb-4">I tuoi ticket</h4>  

    <p *ngIf="tickets.length <= 0" class="text-center my-5 text-muted">Non hai associato nessun ticket...</p>
    <nz-table #ticketsTable *ngIf="tickets.length > 0" [nzData]="tickets" nzShowPagination [nzTotal]="(tickets).length"
      [nzPageSize]="5" [nzPageSizeOptions]="[5, 10, 20, 30, 50, 100]" nzShowSizeChanger class="p-0">
      <thead>
        <tr>
          <th>Titolo</th>
          <th>Data</th>
          <th>Stato</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of ticketsTable.data">
          <td [nzTooltipTitle]="'Messaggio: ' + data.messaggio" nz-tooltip nzTooltipPlacement="top">{{ data.titolo}}
          </td>
          <td>{{ data.data_creazione | date:'dd/MM/yyyy' }}</td>
          <td>{{ data.stato }}</td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>