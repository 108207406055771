// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,  
  // webtvUrl:"http://localhost:4200/",
  // apiUrl:"http://localhost:3500/api/",        
  // recaptcha: '6LdBXTYqAAAAAAlhb9H3knLCpsZOuh23JRPZX0zc'
  webtvUrl:"https://7live-webtv.lamptest.ipsnet.it/",
  apiUrl:"https://7live-webtv-api.lamptest.ipsnet.it/api/",    
  recaptcha: '6LdBXTYqAAAAAAlhb9H3knLCpsZOuh23JRPZX0zc'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
