import { Injectable } from '@angular/core';
import { User, UserBackoffice } from './interfacce.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  TOKEN_KEY = 'auth-token';
  TOKEN_BACKOFFICE_KEY = 'auth-token-backoffice';
  USER_KEY = 'auth-user';
  USER_BACKOFFICE_KEY = 'auth-user-backoffice';

  UserChanged = new Subject<{id: number, nome: string, cognome: string, email: string} | null>();    

  constructor() {}

  getToken(): string {    
    return localStorage.getItem(this.TOKEN_KEY) || '';
  }

  getTokenBackoffice(): string {    
    return localStorage.getItem(this.TOKEN_BACKOFFICE_KEY) || '';
  }

  setToken(token: string): void {
    localStorage.removeItem(this.TOKEN_KEY);
    localStorage.setItem(this.TOKEN_KEY, token);    
  }  

  setTokenBackoffice(token: string): void {
    localStorage.removeItem(this.TOKEN_BACKOFFICE_KEY);
    localStorage.setItem(this.TOKEN_BACKOFFICE_KEY, token);
  }  

  setUser(utente: {id: number, nome: string, cognome: string, email: string}): void {
    localStorage.removeItem(this.USER_KEY);
    localStorage.setItem(this.USER_KEY, JSON.stringify(utente));            
    this.UserChanged.next(utente);
  }

  setUserBackoffice(utente: {id: number, username: string, tipo: string, id_canale: number}): void {
    localStorage.removeItem(this.USER_BACKOFFICE_KEY);
    localStorage.setItem(this.USER_BACKOFFICE_KEY, JSON.stringify(utente));    
  }

  getUser(): User {
    let user = localStorage.getItem(this.USER_KEY);           
    return user ? JSON.parse(user) : '';
  }

  getUserBackoffice(): UserBackoffice {
    let user = localStorage.getItem(this.USER_BACKOFFICE_KEY);           
    return user ? JSON.parse(user) : '';
  }

  clearUser(): void {
    localStorage.removeItem(this.USER_KEY);
    localStorage.removeItem(this.TOKEN_KEY);
    this.UserChanged.next(null);
  }

  clearUserBackoffice(): void {
    localStorage.removeItem(this.USER_BACKOFFICE_KEY);
    localStorage.removeItem(this.TOKEN_BACKOFFICE_KEY);
  }

  clearStorage(): void {    
    localStorage.clear();
  }
}
