import { Component, Input, OnInit } from '@angular/core';
import { Canale } from 'src/app/shared/interfacce.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-canale-thumbnail',
  templateUrl: './canale-thumbnail.component.html',
  styleUrls: ['./canale-thumbnail.component.css']
})
export class CanaleThumbnailComponent implements OnInit {

  constructor() { }

  @Input() canale?: Canale;

  copertina_url: string = '';
  icona_url: string = '';

  apiUrl: string = environment.apiUrl

  ngOnInit(): void {
    this.copertina_url = this.canale?.copertina ? this.apiUrl + 'immagine/' + this.canale?.copertina : "../../../assets/images/default_copertina.png";
    this.icona_url = this.canale?.icona ? this.apiUrl + 'immagine/' + this.canale?.icona : "";
  }

}
