<div class="p-3">
    <ng-template #canaleTitle>
        <h3 class="text-white mb-0">Modifica canale</h3>
    </ng-template>    
    <nz-card [nzTitle]="canaleTitle" [nzActions]="[actionClose, actionEdit]" class="mt-5 mb-5">
        <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" [ngStyle]="{ marginBottom: '12px' }" *ngIf="alertVisible"></nz-alert>
        <form [formGroup]="formCanale" (ngSubmit)="onSubmit()" id="formCanale">
            <div class="row">
                <div class="col">                                            
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Nome</nz-form-label>
                        <nz-form-control>
                            <input nz-input nzSize="large" formControlName="nome" required />
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <nz-form-item class="flex-column mb-2">
                        <nz-form-label class="text-start">Descrizione</nz-form-label>
                        <nz-form-control>
                            <textarea nz-input nzSize="large" formControlName="descrizione"></textarea>
                        </nz-form-control>
                    </nz-form-item>                        
                </div>
            </div>
            <div class="row mt-3">
                <div class="col text-center">
                    <span nzTooltipTitle="Se selezionato, il canale verrà inserito in Home Page nella lista dei canli in evidenza!" nz-tooltip nzTooltipPlacement="top">                        
                        <label nz-checkbox formControlName="evidenza" class="checkbox">Canale in evidenza</label> 
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <nz-tabset>
                        <nz-tab nzTitle="Icona">
                            <div class="row">
                                <div class="my-auto text-center" [ngClass]="{'col-md-6': canale?.icona, 'col': !canale?.icona}">
                                    <nz-upload [(nzFileList)]="fileIconaList" [nzShowButton]="fileIconaList.length <= 0" [nzBeforeUpload]="beforeIconaUpload" nzAccept="image/*" [nzRemove]="removeIconaUpload">
                                        <button nzSize="large" nz-button type="button" class="home-button">
                                            <span class="align-middle" nz-icon nzType="upload"></span> Carica icona
                                        </button>
                                    </nz-upload>                                                                                                       
                                </div>
                                <div class="col-md-6 text-center" *ngIf="canale?.icona">
                                    <button nz-button [nzSize]="'large'" type="button" class="home-button" [nzLoading]="loading" (click)="onDelete('icona', canale!.icona)">Rimuovi icona</button>
                                </div>
                            </div>                      
                            <div class="row">
                                <div class="col my-auto text-center">
                                    <img [src]="icona.length > 0 ? icona : icona_url" *ngIf="canale?.icona || icona" alt="Icona" style="width: 200px; height: auto;" class="mt-4">
                                </div>
                            </div>  
                        </nz-tab>
                        <nz-tab nzTitle="Copertina">
                            <div class="row">
                                <div class="my-auto text-center" [ngClass]="{'col-md-6': canale?.copertina, 'col': !canale?.copertina}">
                                    <nz-upload [(nzFileList)]="fileCopertinaList" [nzShowButton]="fileCopertinaList.length <= 0" [nzBeforeUpload]="beforeCopertinaUpload" nzAccept="image/*" [nzRemove]="removeCopertinaUpload">
                                        <button nzSize="large" nz-button type="button" class="home-button">
                                            <span class="align-middle" nz-icon nzType="upload"></span>
                                            Carica copertina
                                        </button>
                                    </nz-upload>
                                </div>
                                <div class="col-md-6 text-center" *ngIf="canale?.copertina">
                                    <button nz-button [nzSize]="'large'" type="button" class="home-button" [nzLoading]="loading" (click)="onDelete('copertina', canale!.copertina)">Rimuovi copertina</button>
                                </div>
                            </div>                            
                            <div class="row">
                                <div class="col my-auto text-center">
                                    <img [src]="copertina.length > 0 ? copertina : copertina_url" *ngIf="canale?.copertina || copertina" alt="Copertina" class="mt-4 w-100">
                                </div>
                            </div>
                        </nz-tab>                            
                    </nz-tabset>
                </div>
            </div>                                
        </form>
    </nz-card>

    <ng-template #actionClose>
        <button nz-button nzSize="large" type="button" (click)="onAnnulla()">Annulla</button>
    </ng-template>
    <ng-template #actionEdit>
        <button nz-button nzSize="large" type="submit" class="home-button" form="formCanale" [nzLoading]="loading">Modifica</button>
    </ng-template>      
</div>