import { Component, OnInit, inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { ApiService } from 'src/app/shared/api.service';

@Component({
  selector: 'app-sospendi-abbonamento-backoffice',
  templateUrl: './sospendi-abbonamento-backoffice.component.html',
  styleUrls: ['./sospendi-abbonamento-backoffice.component.css']
})
export class SospendiAbbonamentoBackofficeComponent implements OnInit {

  constructor(private apiService: ApiService) { }

  loading: boolean = false;

  readonly #modal = inject(NzModalRef);
  readonly nzModalData = inject(NZ_MODAL_DATA);

  ngOnInit(): void {
  }

  onSospendi() {
    this.loading = true;

    // Sospendo l'abbonamento
    this.apiService.sospendiAbbonamento(this.nzModalData.stripe_sub!).subscribe({
      next: (data) => this.apiService.setAbbonamenti(),
      error: (err) => {
        this.loading = false;
        this.apiService.error(err.error.message);
      }, complete: () => {
        this.loading = false;
        this.#modal.destroy();
      }
    })
    
  }

  onClose() {
    this.#modal.destroy();
  }

}
