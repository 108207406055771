import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ApiService } from 'src/app/shared/api.service';
import { Canale, UserBackoffice } from 'src/app/shared/interfacce.model';
import { StorageService } from 'src/app/shared/storage.service';
import { environment } from 'src/environments/environment';
import { EliminaImmagginiCanaleComponent } from '../elimina-immaggini-canale/elimina-immaggini-canale.component';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modifica-canale-backoffice',
  templateUrl: './modifica-canale-backoffice.component.html',
  styleUrls: ['./modifica-canale-backoffice.component.css']
})
export class ModificaCanaleBackofficeComponent implements OnInit {

  constructor(private apiService: ApiService, private storageService: StorageService, private router: Router, private route: ActivatedRoute, private modal: NzModalService, private viewContainerRef: ViewContainerRef) { }

  formCanale!: FormGroup;

  id: number = 0;
  canale?: Canale;

  icona: string = '';
  icona_url: string = '';
  copertina: string = '';
  copertina_url: string = '';

  fileIconaList: NzUploadFile[] = [];
  fileCopertinaList: NzUploadFile[] = [];

  user!: UserBackoffice;

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  ngOnInit(): void {

    // Recupero l'utente loggato nel backoffice
    this.user = this.storageService.getUserBackoffice();

    // Creo il form con i controlli
    this.formCanale = new FormGroup({
      nome: new FormControl(null),
      descrizione: new FormControl(null),
      icona: new FormControl(null),
      copertina: new FormControl(null),
      evidenza: new FormControl(null),
    })

    // Recupero i parametri passati nell'url
    this.route.params.subscribe(params => {
      this.id = params['id'];

      // Se l'utente è di tipo Operatore controllo che stia modificando il canale di cui ha accesso
      if (this.user.tipo != 'Amministratore') {

        // Se sta provando a modificare un altro canale lo riporto indietro
        if (this.id != this.user.id_canale) {
          this.router.navigate(['/backoffice/home/canali']);
        }
      }

      // Recupero i dati del canale
      this.apiService.getCanale(this.id).subscribe({
        next: (data) => this.canale = data,
        error: (err) => this.router.navigate(['/backoffice/home/canali']),
        complete: () => {

          // Setto l'url per recuperare le immagini di icona e copertina
          this.icona_url = environment.apiUrl + 'immagine/' + this.canale?.icona;
          this.copertina_url = environment.apiUrl + 'immagine/' + this.canale?.copertina;

          // Setto i dati del canale dentro il form
          this.formCanale.setValue({
            nome: this.canale?.nome,
            descrizione: this.canale?.descrizione,
            icona: this.canale?.icona,
            copertina: this.canale?.copertina,
            evidenza: this.canale?.evidenza ? true : false
          })

        }
      })
    });

  }

  beforeIconaUpload = (file: any): boolean => {
    this.fileIconaList = this.fileIconaList.concat(file);

    // Controllo se l'immagine caricata ha il formato JPG o PNG
    const isJpgOrPng = this.fileIconaList.length > 0 ? this.fileIconaList[0]!.type === 'image/jpeg' || this.fileIconaList[0]!.type === 'image/png' : true;

    // Se non ha il formato corretto scarto il file
    if (!isJpgOrPng) {
      this.fileIconaList = [];
      this.onError("L'immagine di copertina deve essere di tipo JPG, PNG o JPEG")
      return false;
    } else {

      // Trasformo l'immagina caricata in base64 e lo salvo nel form
      const reader = new FileReader();
      reader.onload = () => {
        this.alertVisible = false;
        this.icona = reader.result as string;
        this.formCanale.patchValue({ icona: file.name });
      }
      reader.readAsDataURL(file);

      return false;
    }
  };

  beforeCopertinaUpload = (file: any): boolean => {
    this.fileCopertinaList = this.fileCopertinaList.concat(file);

    // Controllo se l'immagine caricata ha il formato JPG o PNG
    const isJpgOrPng = this.fileCopertinaList.length > 0 ? this.fileCopertinaList[0]!.type === 'image/jpeg' || this.fileCopertinaList[0]!.type === 'image/png' : true;

    // Se non ha il formato corretto scarto il file
    if (!isJpgOrPng) {
      this.fileCopertinaList = [];
      this.onError("L'immagine di copertina deve essere di tipo JPG, PNG o JPEG")
      return false;
    } else {

      // Trasformo l'immagina caricata in base64 e lo salvo nel form
      const reader = new FileReader();
      reader.onload = () => {
        this.alertVisible = false;
        this.copertina = reader.result as string;
        this.formCanale.patchValue({ copertina: file.name });
      }
      reader.readAsDataURL(file);

      return false;
    }
  };

  removeIconaUpload = (file: any): boolean => {
    // Rimuovo il file dal form
    this.formCanale.patchValue({ icona: '' });
    this.fileIconaList = [];
    this.icona = '';
    return false;
  }

  removeCopertinaUpload = (file: any): boolean => {
    // Rimuovo il file dal form
    this.formCanale.patchValue({ copertina: '' });
    this.copertina = '';
    this.fileCopertinaList = [];
    return false;
  }

  onAnnulla() {
    this.router.navigate(['/backoffice/home/canali']);
  }

  onDelete(tipo: string, immagine: string) {
    // Formo il modal per l'eliminazione del canale
    let modal = this.modal.create({
      nzTitle: 'Conferma',
      nzContent: EliminaImmagginiCanaleComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {
        tipo: tipo,
        nome_immagine: immagine,
        id_canale: this.canale!.id
      },
      nzFooter: null,
      nzCentered: true,
      nzWidth: 750,
      nzBodyStyle: { 'padding': '0px' }
    });

    modal.afterClose.subscribe((esito: boolean) => {
      if (esito) {
        if (tipo == 'icona') {
          this.canale!.icona = '';
          this.icona = '';
        } else {
          this.canale!.copertina = '';
          this.copertina = '';
        }
      }
    })
  }

  onError(message: string) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = 'error';
    this.loading = false;
  }

  onSubmit() {

    // Formo l'oggetto per la richiesta api
    let canale = {
      nome: this.formCanale.get('nome')?.value,
      descrizione: this.formCanale.get('descrizione')?.value,
      icona: this.formCanale.get('icona')?.value,
      icona_file: this.icona || '',
      copertina: this.formCanale.get('copertina')?.value,
      copertina_file: this.copertina || '',
      evidenza: this.formCanale.get('evidenza')?.value || false
    }

    this.loading = true;

    // Aggiorno il canale nel db
    this.apiService.updateCanale(this.id, canale).subscribe({
      next: (data) => this.apiService.setCanali(),
      error: (err) => this.onError(err.error.message),
      complete: () => {
        this.loading = false;
        this.router.navigate(['/backoffice/home/canali']);
      }
    })
  }
}
