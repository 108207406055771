<div class="p-3">
  <ng-template #canaleTitle>
    <h3 class="text-white mb-0">Aggiungi Live</h3>
  </ng-template>
  <nz-card [nzTitle]="canaleTitle" [nzActions]="[actionClose, actionAdd]" class="mt-5 mb-5">

    <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" [ngStyle]="{ marginBottom: '12px' }" *ngIf="alertVisible"></nz-alert>

    <form [formGroup]="formLive" (ngSubmit)="onSubmit()" id="formLive">      
      <div class="row">
        <div class="col">
          <nz-form-item class="flex-column mb-3">
            <nz-form-label nzRequired class="text-start">Canale</nz-form-label>
            <nz-form-control>
              <nz-select nzShowSearch nzAllowClear formControlName="id_canale" nzSize="large" class="w-100" (ngModelChange)="onCanale($event)">
                <nz-option *ngFor="let canale of canali" [nzValue]="canale.id" [nzLabel]="canale.nome"></nz-option>
                <nz-option [nzValue]="'0'" [nzLabel]="'Altro'" *ngIf="user?.tipo == 'Amministratore'"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div> 
      <div class="row">
        <div class="col">
          <nz-form-item class="flex-column mb-3">
            <nz-form-label nzRequired class="text-start tooltip-label" nzTooltipTitle="Seleziona prima un canale e poi un video On Demand che vuoi mostrare nel live!">Video</nz-form-label>
            <nz-form-control>
              <nz-select nzShowSearch nzAllowClear formControlName="id_ondemand" nzSize="large" class="w-100" [nzDisabled]="!formLive.get('id_canale')?.value"> 
                <nz-option *ngFor="let ondemand of ondemands" [nzValue]="ondemand.id" [nzLabel]="ondemand.titolo + ' | Durata: ' + toHHMMSS(ondemand.durata)"></nz-option>                
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <nz-form-item class="flex-column mb-2">
            <nz-form-label class="text-start">Posizione</nz-form-label>
            <nz-form-control>
                <nz-select nzShowSearch nzAllowClear formControlName="posizione" nzSize="large" class="w-100">
                    <nz-option nzValue="1" nzLabel="Mostra per prima"></nz-option>
                    <nz-option *ngFor="let live of lives" [nzValue]="live.posizione" [nzLabel]="live.posizione + ' - ' + live.titolo"></nz-option>
                    <nz-option nzValue="ultimo" nzLabel="Mostra per ultima"></nz-option>
                </nz-select>
            </nz-form-control>
          </nz-form-item>                   
        </div>
      </div>

    </form>
  </nz-card>

  <ng-template #actionClose>
    <button nz-button nzSize="large" type="button" (click)="onClose()">Annulla</button>
  </ng-template>
  <ng-template #actionAdd>
    <button nz-button nzSize="large" type="submit" class="home-button" form="formLive" [nzLoading]="loading">Aggiungi</button>
  </ng-template>
</div>