<div class="bg-light reset d-flex align-items-center">
    <div class="container-fluid">
        <div class="row">      
            <div class="col-lg-6 d-none d-lg-flex bg-image"></div>        
            <div class="col-lg-6 my-auto">
                <div class="form mx-auto p-lg-5 py-5 px-4">
                    
                    <h3 class="display-4 fw-bold">Reset Password</h3>
                    <p class="text-muted mb-4">Inserisci la tua nuova password!</p>
                    <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" [ngStyle]="{ marginBottom: '12px' }" *ngIf="alertVisible"></nz-alert>      

                    <form [formGroup]="formReset" (ngSubmit)="onSubmit()">
                        <div class="form-data">
                            <div class="forms-inputs mb-4"> <span>* Password</span>
                                <nz-input-group [nzSuffix]="suffixTemplate">
                                    <input [type]="hide ? 'text' : 'password'" nz-input formControlName="password" placeholder="Nuova password" autocomplete />
                                </nz-input-group>
                                <ng-template #suffixTemplate> <span nz-icon [nzType]="hide ? 'eye-invisible' : 'eye'" (click)="hide = !hide"></span></ng-template>
                            </div>
                            <div class="forms-inputs mb-4"> <span>* Conferma Password</span>
                                <nz-input-group [nzSuffix]="suffixTemplateConfirm">
                                    <input [type]="hide_confirm ? 'text' : 'password'" nz-input formControlName="confirm_password" placeholder="Conferma password" autocomplete />
                                </nz-input-group>
                                <ng-template #suffixTemplateConfirm> <span nz-icon [nzType]="hide_confirm ? 'eye-invisible' : 'eye'" (click)="hide_confirm = !hide_confirm"></span></ng-template>
                            </div>
                            <div class="mt-5 mb-3 text-center">
                                <button class="bottone" nzType="primary" nz-button type="submit" [nzLoading]="loading" [disabled]="!formReset.valid">Reset</button>
                                <nz-divider class="text-black" nzText="oppure"></nz-divider>
                                <button nz-button type="button" class="bottone-secondario" routerLink="/login">Torna al Login</button>
                            </div>
                        </div>
                    </form>                    

                </div>
            </div>

        </div>
    </div>
</div>