import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Categoria } from 'src/app/shared/interfacce.model';
import { ApiService } from 'src/app/shared/api.service';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { EliminaCategoriaBackofficeComponent } from './elimina-categoria-backoffice/elimina-categoria-backoffice.component';

@Component({
  selector: 'app-categorie-backoffice',
  templateUrl: './categorie-backoffice.component.html',
  styleUrls: ['./categorie-backoffice.component.css']
})
export class CategorieBackofficeComponent implements OnInit {  

  categorie: Categoria[] = [];  

  titoloFilter: any = '';

  constructor(private apiService: ApiService, private router: Router, private modal: NzModalService, private viewContainerRef: ViewContainerRef) { }

  ngOnInit(): void {

    // Recupero le categorie
    this.apiService.setCategorie();
    this.apiService.categorieChanged.subscribe({
      next: (data) => this.categorie = data,
      error: (err) => this.apiService.error(err.error.message),
      complete: () => { }
    })

  }

  onAdd() {
    this.router.navigate(['/backoffice/home/categorie/add']);
  }

  onEdit(id: number) {
    this.router.navigate(['/backoffice/home/categorie/' + id])
  }

  onElimina(id: number) {

    // Creo il modal per l'eliminazione della categoria
    this.modal.create({
      nzTitle: 'Conferma',
      nzContent: EliminaCategoriaBackofficeComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {
        id_categoria: id
      },
      nzFooter: null,
      nzCentered: true,
      nzWidth: 750,
      nzBodyStyle: {'padding': '0px'}
    });
  }

  onOrdinamentoTitolo(a: Categoria, b: Categoria) {    
    return a.titolo.localeCompare(b.titolo)
  }

  onOrdinamentoDescrizione(a: Categoria, b: Categoria) {    
    return a.descrizione.localeCompare(b.descrizione)
  }

}
