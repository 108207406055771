<div class="bg-light login d-flex align-items-center">
    <div class="container-fluid">
        <div class="row">        
            <div class="col-lg-6 d-none d-lg-flex bg-image"></div>
            <div class="col-lg-6 my-auto">
                <div class="form mx-auto p-lg-5 py-5 px-4">

                    <h3 class="display-4 fw-bold">Login</h3>
                    <p class="text-muted mb-4">Accedi alla tua Area personale, gestisci i tuoi dati e il tuo abbonamento a Seven Live Web TV, per poter visionare i nostri fantastici video On Demand!</p>
                    <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" [ngStyle]="{ marginBottom: '12px' }" *ngIf="alertVisible"></nz-alert>
                    <form [formGroup]="formLogin" (ngSubmit)="onLogin()">                                
                        <div class="form-data">
                            <div class="forms-inputs mb-4">
                                <span>* Email</span>
                                <input nz-input autocomplete="off" type="text" type="email" formControlName="email">
                            </div>
                            <div class="forms-inputs mb-4">
                                <span>* Password</span>
                                <nz-input-group [nzSuffix]="suffixTemplate">
                                    <input [type]="hide ? 'text' : 'password'" nz-input formControlName="password" autocomplete />
                                </nz-input-group>
                                <ng-template #suffixTemplate> <span nz-icon [nzType]="hide ? 'eye-invisible' : 'eye'" (click)="hide = !hide" style="cursor: pointer"></span></ng-template>
                            </div>
                            <div class="mb-1 text">
                                <p routerLink="/reset">Password dimenticata?</p>
                            </div>
                            <div class="my-3 text-center">
                                <button class="bottone m-0" nzType="primary" nz-button type="submit" nzSize="large" [nzLoading]="loading" [disabled]="!formLogin.valid">Accedi</button>
                                <nz-divider class="text-black" nzText="oppure"></nz-divider>
                                <button nz-button type="button" class="bottone-secondario" routerLink="/registrazione">Registrati</button>
                            </div>
                        </div>
                    </form>
                            
                </div>
            </div>
    </div>              
</div>