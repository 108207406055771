<nz-card [nzActions]="[actionClose, actionAdd]">
    <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" [ngStyle]="{ marginBottom: '12px' }" *ngIf="alertVisible"></nz-alert>
    <form [formGroup]="formCategoria" (ngSubmit)="onSubmit()" id="formCategoria">
        <div class="row">
            <div class="col">                                                               
                <nz-form-item class="flex-column mb-3">
                    <nz-form-label nzRequired class="text-start">Titolo</nz-form-label>
                    <nz-form-control>
                        <input nz-input nzSize="large" formControlName="titolo" required />
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <nz-form-item class="flex-column mb-2">
                    <nz-form-label class="text-start">Descrizione</nz-form-label>
                    <nz-form-control>
                        <textarea nz-input nzSize="large" formControlName="descrizione"></textarea>
                    </nz-form-control>
                </nz-form-item>                    
            </div>
        </div>
        <div class="row mt-3">
            <div class="col text-center">
                <span nzTooltipTitle="Se selezionato, la categoria comparirà in Home Page (Si possono selezionare fino a 5 categorie)!" nz-tooltip nzTooltipPlacement="top">
                    <label nz-checkbox formControlName="home" class="checkbox">Mostra in Home Page</label> 
                </span>
            </div>
        </div>                              
    </form>  
</nz-card>
<ng-template #actionClose>
    <button type="button" nz-button nzSize="large" (click)="onClose()">Annulla</button>
</ng-template>
<ng-template #actionAdd>
    <button nz-button nzSize="large" [nzLoading]="loading" type="submit" class="home-button" form="formCategoria">Aggiungi</button>
</ng-template>