import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from "./api.service";
import { StorageService } from "./storage.service";
import { Abbonamento, Ondemand } from './interfacce.model';

@Injectable({
  providedIn: 'root'
})
export class AbbonamentoGuard  {

  constructor(private router: Router, private apiService: ApiService, private storageService: StorageService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return new Observable<boolean>(obs => {

      const string = route.paramMap.get('id') || '';      
      const id_ondemand = atob(string).split('id_ondemand=')[1];            

      const user = this.storageService.getUser();
      let ondemand: Ondemand;
      let abbonamento: Abbonamento;
      let check: { status: string, stripe_scadenza: number };

      // Recupero l'ondemand
      this.apiService.getOndemandHome(+id_ondemand).subscribe({
        next: (data) => ondemand = data,
        error: (err) => {
          this.router.navigate(['/abbonamento']);
          return obs.next(false);
        },
        complete: () => {

          // Controllo se l'ondemand è visibile solo tramite abbonamento o no
          if (ondemand.abbonamento) {

            // Se si controllo se c'è l'utente
            if (!user) {
              this.router.navigate(['/abbonamento'])
            } else {

              // Recupero l'abbonamento del cliente
              this.apiService.getAbbonamentoCliente(user.id).subscribe({
                next: (data) => {
                  abbonamento = data;

                  // Se non ha l'abbonamento non procedo
                  if (!abbonamento) {
                    this.router.navigate(['/abbonamento']);
                    return obs.next(false);
                  }
                },
                error: (err) => {
                  this.router.navigate(['/abbonamento']);
                  return obs.next(false);
                },
                complete: () => {

                  // Se ha l'abbonamento controllo la sua validità
                  this.apiService.checkAbbonamento(abbonamento.stripe_sub).subscribe({
                    next: (data) => check = data,
                    error: (err) => {
                      this.router.navigate(['/abbonamento']);
                      return obs.next(false);
                    },
                    complete: () => {

                      // controllo scadenza abbonamento
                      let scadenza = new Date(abbonamento.stripe_scadenza).getTime() - new Date().getTime();

                      // Se l'abbonamento di stripe non è attivo lo rimuovo            
                      if (check.status != 'active') {

                        // Se l'abbonamento è scaduto ma non è attivo rimuovo l'abbonamento
                        this.apiService.rimozioneAbbonamento(abbonamento.stripe_sub, abbonamento.id).subscribe({
                          next: (data) => { },
                          error: (err) => {
                            this.router.navigate(['/abbonamento']);
                            return obs.next(false);
                          },
                          complete: () => {
                            this.router.navigate(['/abbonamento']);
                            return obs.next(false);
                          }
                        })

                      } else {

                        // Se l'abbonamento è attivo ma la scadenza è minore = a zero aggiorno l'abbonamento
                        if (scadenza <= 0) {
                          // Aggiorno la data di scadenza 
                          this.apiService.updateAbbonamento(abbonamento.id, check.stripe_scadenza).subscribe({
                            next: (data) => { },
                            error: (err) => {
                              this.router.navigate(['/abbonamento']);
                              return obs.next(false);
                            },
                            complete: () => { return obs.next(true) }

                          })
                        } else {
                          return obs.next(true);
                        }
                      }

                    }
                  })
                }
              })
            }
          } else {
            return obs.next(true);
          }
        }
      })      


    });
  }
}