<div class="bg-light reset d-flex align-items-center">
    <div class="container-fluid">
        <div class="row">         
            <div class="col-lg-6 d-none d-lg-flex bg-image"></div>         
            <div class="col-lg-6 my-auto">
                <div class="form mx-auto p-lg-5 py-5 px-4">
                    
                    <h3 class="display-4 fw-bold">Richiesta reset</h3>
                    <p class="text-muted mb-4">Inserisci la tua email per richiedere il reset della password!
                    </p>
                    <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" [ngStyle]="{ marginBottom: '12px' }" *ngIf="alertVisible"></nz-alert>      
                    <form [formGroup]="formReset" (ngSubmit)="onSubmit()">
                        <div class="form-data">
                            <div class="forms-inputs mb-4"> <span>* Email</span> 
                                <input nz-input autocomplete="off" type="text" type="email" formControlName="email">
                            </div>
                            <div class="mt-5 mb-3 text-center">
                                <button class="bottone m-0" nzType="primary" nz-button type="submit" [nzLoading]="loading" [disabled]="!formReset.valid">Recupera</button>
                                <nz-divider class="text-black" nzText="oppure"></nz-divider>
                                <button nz-button type="button" class="bottone-secondario" routerLink="/login">Torna al Login</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>

        </div>
    </div>
</div>