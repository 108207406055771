<div class="p-3">
    <ng-template #clienteTitle>
        <h3 class="text-white mb-0">Aggiungi Cliente</h3>
    </ng-template>    
    <nz-card [nzTitle]="clienteTitle" [nzActions]="[actionClose, actionAdd]" class="mt-5 mb-5">
        <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" [ngStyle]="{ marginBottom: '12px' }" *ngIf="alertVisible"></nz-alert>
        <form [formGroup]="formCliente" (ngSubmit)="onSubmit()" id="formCliente">                        
            <div class="row">
                <div class="col-md-6">                                                                               
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Cognome</nz-form-label>
                        <nz-form-control>
                            <input nz-input nzSize="large" formControlName="cognome" required />                 
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div class="col-md-6">
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Cognome</nz-form-label>
                        <nz-form-control>
                            <input nz-input nzSize="large" formControlName="nome" required/>               
                        </nz-form-control>
                    </nz-form-item>                    
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Email</nz-form-label>
                        <nz-form-control>
                            <input nz-input nzSize="large" formControlName="email" type="email" required />           
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div class="col-md-6">                    
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Password</nz-form-label>
                        <nz-form-control>
                            <nz-input-group [nzSuffix]="passwordTemplate" nzSize="large">
                                <input [type]="hide ? 'text' : 'password'" nz-input formControlName="password" required />
                            </nz-input-group>
                            <ng-template #passwordTemplate>
                                <span nz-icon [nzType]="hide ? 'eye-invisible' : 'eye'" (click)="hide = !hide"></span>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Data di nascita</nz-form-label>
                        <nz-form-control>
                            <nz-date-picker formControlName="data_nascita" [nzMode]="'date'" nzSize="large" [nzFormat]="'dd/MM/yyyy'" class="w-100" required></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div class="col-md-6">    
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label class="text-start">Luogo di nascita</nz-form-label>
                        <nz-form-control>
                            <input nz-input nzSize="large" formControlName="luogo_nascita" />
                        </nz-form-control>
                    </nz-form-item>
                </div>                
            </div>
            <div class="row">
                <div class="col-md-6">              
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label class="text-start">Codice fiscale</nz-form-label>
                        <nz-form-control>
                            <input nz-input nzSize="large" formControlName="codice_fiscale" />                                   
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div class="col-md-6"> 
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label class="text-start">Telefono</nz-form-label>
                        <nz-form-control>
                            <input nz-input nzSize="large" formControlName="telefono" type="tel" />                                   
                        </nz-form-control>
                    </nz-form-item>                                       
                </div>
            </div>
            <div class="row">
                <div class="col text-center mt-3">
                    <span nzTooltipTitle="Se selezionato, il cliente accetta di ricevere di ricevere mail dalla Newsletter!" nz-tooltip nzTooltipPlacement="top">                        
                        <label nz-checkbox formControlName="newsletter" class="checkbox">Newsletter</label> 
                    </span>
                </div>
            </div>            
        </form>
    </nz-card>    
    <ng-template #actionClose>
        <button type="button" nz-button nzSize="large" (click)="onClose()">Annulla</button>
    </ng-template>
    <ng-template #actionAdd>
        <button nz-button nzSize="large" [nzLoading]="loading" type="submit" class="home-button" form="formCliente" [disabled]="!formCliente.valid">Aggiungi</button>        
    </ng-template>
</div>