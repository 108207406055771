<div class="container pb-5">

  <!-- LOGO MOBILE -->
  <div class="d-flex justify-content-center py-md-3 py-5">
    <img class="d-lg-none d-block" src="../../../assets/images/Logo bianco.png" height="40px" alt="">
  </div>
  <!-- LOGO MOBILE -->

  <!-- TITOLO PAGINA -->
  <div class="row">
    <div class="col">
      <h3 class="display-5 text-white fw-bold">On Demand</h3>
      <p class="text-white">Scopri i video On Demand e guardali quando vuoi</p>
    </div>
  </div>
  <!-- TITOLO ON DEMAND -->

  <!-- BANNER -->
  <div class="row">
    <div class="col mt-3">

      <!-- NO ABBONAMENTO || NO LOGIN -->
      <div *ngIf="abbonamentoScaduto">
        <div class="avviso px-3 py-5 bg-dark rounded d-flex justify-content-center flex-column">
          <h3 class="text-white text-center">
            {{user?.id ? 'Sembra che tu non abbia nessun abbonamento attivo...' : 'Per visualizzare questo video On Demand accedi o registrati prima.'}}
          </h3>
          <p class="text-white text-center mt-2">
            {{user?.id ? 'Abbonati ora per poter visionare il video On Demand!' : "Dopo aver effettuato la registrazione o l'accesso, se non sei ancora abbonato a Seven Live Web TV, effettualo per poter visionare il video On Demand."}}
          </p>
          <button class="bottone mt-3" nzType="primary" nz-button
            [routerLink]="user?.id ? '/profilo/abbonamento' : '/login'">{{user?.id ? 'Abbonati ora': 'Login'}}</button>
        </div>
      </div>
      <!-- NO ABBONAMENTO || NO LOGIN -->

      <!-- BANNER SELEZIONE VIDEO -->
      <div *ngIf="!video && !abbonamentoScaduto">
        <div class="avviso px-3 py-5 bg-dark rounded d-flex justify-content-center flex-column">
          <h3 class="text-white text-center mb-0">Premi o clicca su un video On Demand per poterlo vedere</h3>
        </div>
      </div>
      <!-- BANNER SELEZIONE VIDEO -->

    </div>
  </div>
  <!-- BANNER -->

  <ng-container *ngIf="video && !loading && !abbonamentoScaduto">
    <!-- PLAYER -->
    <div class="row">
      <div class="col">
        <app-player ></app-player>
      </div>
    </div>
    <!-- PLAYER -->
  </ng-container>

  <!-- <ng-container *ngIf="!loading && ondemands_gratuiti.length > 0"> -->

    <!-- TITOLO ON DEMAND GRATUITI -->
    <!-- <div class="row mt-5 mb-3">
      <div class="col">
        <h4 class="display-5 text-white fw-bold">Scopri i nostri video On Demand gratuiti</h4>
      </div>
    </div> -->
    <!-- TITOLO ON DEMAND GRATUITI -->

    <!-- ON DEMAND GRATUITI -->
    <!-- <div class="row g-2">
      <div class="col-md-4 mt-3" *ngFor="let ondemand of ondemands_gratuiti.slice(0,num_ondemands_gratuiti);">
        <app-video-thumbnail (click)="onOndemand(ondemand.id)" [ondemand]="ondemand" ></app-video-thumbnail>
      </div>
    </div> -->
    <!-- ON DEMAND -->

    <!-- BUTTON ON DEMAND -->
    <!-- <div class="row">
      <div class="text-center" [class.col-md-6]="num_ondemands_gratuiti > 9" [class.col]="num_ondemands_gratuiti <= 9"
        *ngIf="num_ondemands_gratuiti < ondemands_gratuiti.length">
        <button (click)="onMostraGratuiti(6)" class="bottone mt-4" nzType="primary" nz-button nzSize="large">Vedi di
          più</button>
      </div>
      <div class="text-center"
        [class.col-md-6]="num_ondemands_gratuiti > 9 && num_ondemands_gratuiti < ondemands_gratuiti.length"
        [class.col]="num_ondemands_gratuiti > ondemands_gratuiti.length" *ngIf="num_ondemands_gratuiti > 9">
        <button (click)="onMostraGratuiti(-6)" class="bottone mt-4" nzType="primary" nz-button nzSize="large">Vedi di
          meno</button>
      </div>
    </div> -->
    <!-- BUTTON ON DEMAND -->

  <!-- </ng-container> -->

  <!-- TITOLO ON DEMAND -->
  <div class="row mt-5">
    <div class="col">
      <h4 class="display-5 text-white fw-bold">Rivivi i nostri momenti</h4>
    </div>
  </div>
  <!-- TITOLO ON DEMAND -->

  <!-- FILTRI -->
  <div class="row my-3 g-1">
    <div class="col-lg-4">
      <nz-input-group [nzSuffix]="suffixIconSearch" class="ricerca" nzSize="large">
        <input id="queryFiltroVideo" nz-input type="text" [formControl]="titoloFilter" (ngModelChange)="onFiltro()"
          placeholder="Cerca...">
      </nz-input-group>
      <ng-template #suffixIconSearch>
        <span nz-icon nzType="search"></span>
      </ng-template>
    </div>
    <div class="col-lg-3">
      <select name="canale" class="form-control ricerca ricerca-select" (ngModelChange)="onFiltro()" [formControl]="canaleFilter">
        <option value="-1" selected>Tutti i canali</option>
        <option *ngFor="let canale of canali" [value]="canale.id">{{canale.nome}}</option>
      </select>
    </div>
    <div class="col-lg-3">
      <select name="canale" class="form-control ricerca ricerca-select" (change)="onFiltro()" [formControl]="categoriaFilter">
        <option value="-1" selected>Tutte le categorie</option>
        <option *ngFor="let categoria of categorie" [value]="categoria.id">{{categoria.titolo}}</option>
      </select>
    </div>
    <div class="col-lg-2">
      <button nz-button nzSize="large" class="bottone px-4 w-100" style="padding-top: .375rem; padding-bottom: .375rem;" type="button" (click)="onClearFiltri()">Pulisci i filtri</button>
    </div>
  </div>
  <!-- FILTRI -->

  <!-- LOADER -->
  <div class="row" *ngIf="loading">
    <div class="col text-center">
      <app-video-loading></app-video-loading>
    </div>
  </div>
  <!-- LOADER -->

  <ng-container *ngIf="!loading">

    <!-- NESSUN RISULTATO -->
    <div class="row" *ngIf="ondemands_filtrati.length < 1">
      <div class="col">
        <h5 class="text-center text-white my-4">La ricerca non ha prodotto nessun risultato</h5>
      </div>
    </div>
    <!-- NESSUN RISULTATO -->

    <!-- ON DEMAND -->
    <div class="row g-2" *ngIf="ondemands_filtrati.length > 0">
      <div class="col-md-4 mt-3" *ngFor="let ondemand of ondemands_filtrati.slice(0,num_ondemands);">
        <app-video-thumbnail (click)="onOndemand(ondemand.id)" [ondemand]="ondemand" ></app-video-thumbnail>
      </div>
    </div>
    <!-- ON DEMAND -->    

    <!-- BUTTON ON DEMAND -->
    <div class="row mt-4" *ngIf="ondemands_filtrati.length > 0">

      <div [ngClass]="{'col-6 pe-0': num_ondemands > 9, 'col': num_ondemands <= 9}" *ngIf="num_ondemands < ondemands_filtrati.length" class="ciao">
        <nz-divider class="text-white ondemand-divider" [nzOrientation]="num_ondemands <= 9 ? 'center' : 'right'" [nzText]="text">
          <ng-template #text>
            <div class="text-white button-divider" (click)="onMostra(6)">
              <span class="align-middle" nz-icon nzType="down" nzTheme="outline"></span>
            </div>          
          </ng-template>
        </nz-divider>
      </div>
      <div [ngClass]="{'col-6 ps-0': num_ondemands > 9 && num_ondemands < ondemands_filtrati.length, 'col': num_ondemands > ondemands_filtrati.length}" *ngIf="num_ondemands > 9">
        <nz-divider class="text-white ondemand-divider" [nzOrientation]="num_ondemands > ondemands_filtrati.length ? 'center' : 'left'" [nzText]="text">
          <ng-template #text>
            <div class="text-white button-divider" (click)="onMostra(-6)">
              <span class="align-middle" nz-icon nzType="up" nzTheme="outline"></span>
            </div>          
          </ng-template>
        </nz-divider>
      </div>

    </div>
    <!-- BUTTON ON DEMAND -->

  </ng-container>

</div>