import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/shared/api.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {

  constructor(private apiService: ApiService) { }

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  formReset!: FormGroup;

  ngOnInit(): void {
    this.formReset = new FormGroup({
      email: new FormControl(null)
    })
  }

  onError(message: string) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = 'error';
    this.loading = false;
  }

  onSubmit() {
    this.loading = true;

    let email = this.formReset.get('email')?.value;

    this.apiService.richiestaReset(email).subscribe({
      next: (data) => { },
      error: (err) => this.onError(err.error.message),
      complete: () => {
        this.alertMessage = "Un'email è stata inviata all'indirizzo inserito";
        this.alertVisible = true;
        this.alertType = 'success';
        this.loading = false;
      }

    })

  }

}
