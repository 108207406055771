import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { Ondemand, UserBackoffice } from 'src/app/shared/interfacce.model';
import { EliminaOndemandBackofficeComponent } from './elimina-ondemand-backoffice/elimina-ondemand-backoffice.component';
import { StorageService } from 'src/app/shared/storage.service';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-ondemand-backoffice',
  templateUrl: './ondemand-backoffice.component.html',
  styleUrls: ['./ondemand-backoffice.component.css']
})
export class OndemandBackofficeComponent implements OnInit {

  ondemands: Ondemand[] = [];

  titoloFilter: any = '';
  canaleFilter: any = '';

  user?: UserBackoffice;

  constructor(private apiService: ApiService, private storageService: StorageService, private router: Router, private modal: NzModalService, private viewContainerRef: ViewContainerRef) { }

  ngOnInit(): void {

    // Recupero l'utente loggato
    this.user = this.storageService.getUserBackoffice();

    // Recupero gli ondemand
    this.apiService.setOndemand();
    this.apiService.ondemandChanged.subscribe({
      next: (data) => {
        // Se l'utente è amministratore recupero tutti gli ondemand
        if (this.user?.tipo == 'Amministratore')
          this.ondemands = data;
        else {
          // Filtro gli ondemand per il canale legato all'utente
          this.ondemands = data.filter((ondemand: Ondemand) => {
            let canali = JSON.parse(ondemand.id_canale);
            return canali.indexOf(this.user?.id_canale) >= 0;
          })
        }
      },
      error: (err) => this.router.navigate(['/backoffice/home/ondemand']),
      complete: () => { }
    })

  }

  onAdd() {
    this.router.navigate(['/backoffice/home/ondemand/add']);
  }

  onEdit(id: number) {
    this.router.navigate(['/backoffice/home/ondemand/' + id])
  }

  onElimina(id: number) {
    this.modal.create({
      nzTitle: 'Conferma',
      nzContent: EliminaOndemandBackofficeComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {
        id_ondemand: id
      },
      nzFooter: null,
      nzCentered: true,
      nzWidth: 750,
      nzBodyStyle: { 'padding': '0px' }
    });
  }

  onOrdinamentoTitolo(a: Ondemand, b: Ondemand) {
    return a.titolo.localeCompare(b.titolo)
  }

  onOrdinamentoCanale(a: Ondemand, b: Ondemand) {
    return a.canale.localeCompare(b.canale)
  }

  onOrdinamentoCategoria(a: Ondemand, b: Ondemand) {
    return a.categoria.localeCompare(b.categoria)
  }

}
