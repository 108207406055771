<div class="p-3">
    <ng-template #clienteTitle>
        <h3 class="text-white mb-0">Modifica password</h3>
    </ng-template>    
    <nz-card [nzTitle]="clienteTitle" [nzActions]="[actionClose, actionEdit]" class="mt-5 mb-5">
        <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" [ngStyle]="{ marginBottom: '12px' }" *ngIf="alertVisible"></nz-alert>
        <form [formGroup]="formPassword" (ngSubmit)="onSubmit()" id="formPassword">                                    
            <div class="row">                
                <div class="col-md-6">
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Nuova Password</nz-form-label>
                        <nz-form-control>
                            <nz-input-group [nzSuffix]="passwordTemplate" nzSize="large">
                                <input [type]="hide ? 'text' : 'password'" nz-input formControlName="password" required />
                            </nz-input-group>
                            <ng-template #passwordTemplate>
                                <span nz-icon [nzType]="hide ? 'eye-invisible' : 'eye'" (click)="hide = !hide"></span>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div class="col-md-6">                    
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Conferma Password</nz-form-label>
                        <nz-form-control>
                            <nz-input-group [nzSuffix]="passwordTemplate" nzSize="large">
                                <input [type]="hide_confirm ? 'text' : 'password'" nz-input formControlName="confirm_password" required />
                            </nz-input-group>
                            <ng-template #passwordTemplate>
                                <span nz-icon [nzType]="hide_confirm ? 'eye-invisible' : 'eye'" (click)="hide_confirm = !hide_confirm"></span>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>       
        </form>
    </nz-card>

    <ng-template #actionClose>
        <button type="button" nz-button nzSize="large" (click)="onClose()">Annulla</button>
    </ng-template>
    <ng-template #actionEdit>
        <button nz-button nzSize="large" [nzLoading]="loading" type="submit" class="home-button" form="formPassword">Modifica</button>        
    </ng-template>
</div>