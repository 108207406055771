
import { Pipe, PipeTransform } from "@angular/core";
import { Abbonamento, Canale, Categoria, Cliente, Live, News, Ondemand, Ticket, Utente } from "./interfacce.model";
import { DatePipe } from "@angular/common";

@Pipe({
    name: 'searchTitoloCanale',
    pure: false
})
export class SearchTitoloCanalePipe implements PipeTransform {
    transform(canali: any, query: string): any {
        return canali.filter((canale: Canale) => canale.nome?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchDescrizioneCanale',
    pure: false
})
export class SearchDescrizioneCanalePipe implements PipeTransform {
    transform(canali: any, query: string): any {
        return canali.filter((canale: Canale) => (canale.descrizione || '')?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchTitoloCategoria',
    pure: false
})
export class SearchTitoloCategoriaPipe implements PipeTransform {
    transform(categorie: any, query: string): any {
        return categorie.filter((categoria: Categoria) => categoria.titolo?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchNomeCognomeCliente',
    pure: false
})
export class SearchNomeCognomeClientePipe implements PipeTransform {
    transform(clienti: any, query: string): any {
        return clienti.filter((cliente: Cliente) => (cliente?.cognome.toString().trim().toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) !== -1 || cliente.nome.toString().trim().toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) !== -1))
    }
}

@Pipe({
    name: 'searchEmailCliente',
    pure: false
})
export class SearchEmailClientePipe implements PipeTransform {
    transform(clienti: any, query: string): any {
        return clienti.filter((cliente: Cliente) => cliente.email?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchClienteAbbonamento',
    pure: false
})
export class SearchClienteAbbonamentoPipe implements PipeTransform {
    transform(abbonamenti: any, query: string): any {
        return abbonamenti.filter((abbonamento: Abbonamento) => abbonamento.cliente?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchStatoAbbonamento',
    pure: false
})
export class SearchStatoAbbonamentoPipe implements PipeTransform {
    transform(abbonamenti: any, query: string): any {
        return abbonamenti.filter((abbonamento: Abbonamento) => abbonamento.stato?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchTitoloLive',
    pure: false
})
export class SearchTitoloLivePipe implements PipeTransform {
    transform(lives: any, query: string): any {
        return lives.filter((live: Live) => live.titolo?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchCanaleLive',
    pure: false
})
export class SearchCanaleLivePipe implements PipeTransform {
    transform(lives: any, query: string): any {
        return lives.filter((live: Live) => live.canale?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchTitoloNews',
    pure: false
})
export class SearchTitoloNewsPipe implements PipeTransform {
    transform(notizie: any, query: string): any {
        return notizie.filter((notizia: News) => notizia.titolo?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchDataNews',
    pure: false
})
export class SearchDataNewsPipe implements PipeTransform {

    constructor(private datePipe: DatePipe) { }

    transform(notizie: any, query: string): any {        
        return notizie.filter((notizia: News) => (this.datePipe.transform(notizia.data_creazione, 'dd/MM/YYYY') || '').toString()?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchUsernameUtente',
    pure: false
})
export class SearchUsernameUtentePipe implements PipeTransform {
    transform(utenti: any, query: string): any {
        return utenti.filter((utente: Utente) => utente.username?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchTipoUtente',
    pure: false
})
export class SearchTipoUtentePipe implements PipeTransform {
    transform(utenti: any, query: string): any {
        return utenti.filter((utente: Utente) => utente.tipo?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchClienteTicket',
    pure: false
})
export class SearchClienteTicketPipe implements PipeTransform {
    transform(tickets: any, query: string): any {
        return tickets.filter((ticket: Ticket) => ticket.cliente?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchStatoTicket',
    pure: false
})
export class SearchStatoTicketPipe implements PipeTransform {
    transform(tickets: any, query: string): any {
        return tickets.filter((ticket: Ticket) => ticket.stato?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchTitoloOndemand',
    pure: false
})
export class SearchTitoloOndemandPipe implements PipeTransform {
    transform(ondemands: any, query: string): any {
        return ondemands.filter((ondemand: Ondemand) => ondemand.titolo?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}

@Pipe({
    name: 'searchCanaleOndemand',
    pure: false
})
export class SearchCanaleOndemandPipe implements PipeTransform {
    transform(ondemands: any, query: string): any {
        return ondemands.filter((ondemand: Ondemand) => ondemand.canale?.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    }
}