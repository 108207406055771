<img class="bg" #img [src]="copertina_url"  alt="">
<div class="overlay">        
    <div class="immagine vrappatore">
        <img #icon [src]="icona_url" class="watermark" alt="Icona" *ngIf="canale?.icona" (error)="icon.style.display = 'none'">
    </div>
    <div class="testi w-100">        
        <div class="vrappatore">
            <div class="descrizione">
                <h5 id="titolo" class="p-0 m-0 text-white testo crop" [attr.title]="canale?.nome">{{canale?.nome}}</h5>
                <p id="desc" class="p-0 m-0 text-white testo crop">{{canale?.descrizione}}</p>
            </div>
        </div>
    </div>
</div>