<div class="p-3">
    <ng-template #canaleTitle>
        <h3 class="text-white mb-0">Aggiungi On Demand</h3>
    </ng-template>
    <nz-card [nzTitle]="canaleTitle" [nzActions]="[actionClose, actionAdd]" class="mt-5 mb-5">
        <nz-alert nzType="error" nzShowIcon nzMessage="Per ragioni di compatibilità, assicurati che i video siano in formato mp4 (h264/aac)" [ngStyle]="{ marginBottom: '12px' }" [nzIcon]="iconAlert"></nz-alert>
        <ng-template #iconAlert><span nz-icon nzType="warning" nzTheme="outline"></span></ng-template>
        <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" [ngStyle]="{ marginBottom: '12px' }" *ngIf="alertVisible"></nz-alert>

        <form [formGroup]="formOndemand" (ngSubmit)="onSubmit()" id="formOndemand">
            <div class="row">
                <div class="col">
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Titolo</nz-form-label>
                        <nz-form-control>
                            <input nz-input nzSize="large" formControlName="titolo" required/>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label class="text-start">Descrizione</nz-form-label>
                        <nz-form-control>
                            <textarea nz-input nzSize="large" formControlName="descrizione"></textarea>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Canale</nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearch nzAllowClear formControlName="id_canale" nzMode="multiple" nzSize="large" class="w-100" required>
                                <nz-option *ngFor="let canale of canali" [nzValue]="canale.id" [nzLabel]="canale.nome"></nz-option>                                
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div class="col-md-6">
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Categoria</nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearch nzAllowClear formControlName="id_categoria" nzMode="multiple" nzSize="large" class="w-100"  required>
                                <nz-option *ngFor="let categoria of categorie" [nzValue]="categoria.id" [nzLabel]="categoria.titolo"></nz-option>                                
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col text-center">
                    <span nzTooltipTitle="Se selezionato, il video On Demand sarà disponibile alla visione solo tramite l'abbonamento!" nz-tooltip nzTooltipPlacement="top">                        
                        <label nz-checkbox formControlName="abbonamento" class="checkbox">Visione tramite abbonamento</label> 
                    </span>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col text-center">
                    <nz-tabset>
                        <nz-tab nzTitle="* Video">

                            <div class="text-center w-100">
                                <uppy-dashboard class="my-uppy-dashboard" [uppy]="uppy" [props]="{inline: true, theme: 'dark', width: '100%', height: 450, showProgressDetails: true, proudlyDisplayPoweredByUppy: false, hideUploadButton: true}" ></uppy-dashboard>
                            </div>                                                        

                        </nz-tab>
                        <nz-tab nzTitle="* Copertina">
                            <nz-upload [(nzFileList)]="imageList" [nzShowButton]="imageList.length <= 0"
                                [nzBeforeUpload]="beforeImageUpload" nzAccept="image/*" [nzRemove]="removeImageUpload">
                                <button nzSize="large" nz-button type="button" class="home-button">
                                    <span class="align-middle" nz-icon nzType="upload"></span>
                                    Carica immagine
                                </button>
                            </nz-upload>

                            <img [src]="copertina" alt="Copertina" class="mt-4 w-100" *ngIf="copertina.length > 0">
                        </nz-tab>
                    </nz-tabset>
                </div>
            </div>            
        </form>
    </nz-card>

    <ng-template #actionClose>
        <button nz-button nzSize="large" type="button" (click)="onClose()">Annulla</button>
    </ng-template>
    <ng-template #actionAdd>
        <button nz-button nzSize="large" type="submit" class="home-button" form="formOndemand" [nzLoading]="loading">Aggiungi</button>
    </ng-template>
</div>