<div class="content py-5">
  <div class="container">
    <div class="row d-lg-none d-flex mb-4">
      <div class="col text-center" routerLink="/profilo" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="profilo-active">
        <i class="nav-icon bi bi-person-fill"></i>
      </div>
      <div class="col text-center" routerLink="/profilo/abbonamento" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="profilo-active">
        <i class="nav-icon bi bi-credit-card-fill"></i>
      </div>
      <div class="col text-center" routerLink="/profilo/ticket" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="profilo-active">
        <i class="nav-icon bi bi-ticket-detailed-fill"></i>
      </div>
      <div class="col text-center" nz-popconfirm nzPopconfirmTitle="Sei sicuro di voler effettuare il logout?" (nzOnConfirm)="onLogout()" [nzOkText]="'Esci'">
        <i class="nav-icon bi bi-box-arrow-right"></i>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4 d-lg-block d-none">        
          <div class="row">
            <div class="col">
              <button nz-button type="primary" nzSize="large" class="menu-button" routerLink="/profilo" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active text-white">
                <span>Gestisci il tuo profilo</span>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button nz-button type="primary" nzSize="large" class="menu-button" routerLink="/profilo/abbonamento" routerLinkActive="active text-white">
                <span>Gestisci il tuo abbonamento</span>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button nz-button type="primary" nzSize="large" class="menu-button" routerLink="/profilo/ticket" routerLinkActive="active text-white">
                <span>Apri un ticket</span>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button nz-popconfirm nzPopconfirmTitle="Sei sicuro di voler effettuare il logout?" (nzOnConfirm)="onLogout()" nz-button nzType="primary" nzSize="large" class="bg-danger menu-button-danger" [nzOkText]="'Esci'">Effettua il logout</button>
            </div>          
        </div>
      </div>
      <div class="col-lg-8 border-start">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
