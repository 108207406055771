import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ApiService } from 'src/app/shared/api.service';
import { News } from 'src/app/shared/interfacce.model';
import { environment } from 'src/environments/environment';
import { EliminaImmagginiNewsComponent } from '../elimina-immaggini-news/elimina-immaggini-news.component';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modifica-news-backoffice',
  templateUrl: './modifica-news-backoffice.component.html',
  styleUrls: ['./modifica-news-backoffice.component.css']
})
export class ModificaNewsBackofficeComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute, private datePipe: DatePipe, private modal: NzModalService, private viewContainerRef: ViewContainerRef) { }

  formNews!: FormGroup;

  id: number = 0;
  news?: News;

  copertina: string = '';
  copertina_url: string = '';

  imageList: NzUploadFile[] = [];

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formNews = new FormGroup({
      titolo: new FormControl(null),
      descrizione: new FormControl(null),
      copertina: new FormControl(null),
      data: new FormControl(null)
    })

    // Recupero i parametri nell'url
    this.route.params.subscribe(params => {
      this.id = params['id'];

      // Recupero la notizia
      this.apiService.getNotizia(this.id).subscribe({
        next: (data) => this.news = data,
        error: (err) => this.router.navigate(['/backoffice/home/news']),
        complete: () => {

          // Formo l'url per la copertina della news
          this.copertina_url = environment.apiUrl + 'immagine/' + this.news?.copertina;

          // Setto i dati della news nel form
          this.formNews.setValue({
            titolo: this.news?.titolo,
            descrizione: this.news?.descrizione,
            data: this.news?.data_creazione,
            copertina: this.news?.copertina
          })
        }
      })

    });
  }

  onClose() {
    this.router.navigate(['/backoffice/home/news']);
  }

  beforeImageUpload = (file: any): boolean => {
    this.imageList = this.imageList.concat(file);

    // Controllo se l'immagine caricata ha il formato JPG o PNG
    const isJpgOrPng = this.imageList.length > 0 ? this.imageList[0]!.type === 'image/jpeg' || this.imageList[0]!.type === 'image/png' : true;

    // Se non ha il formato corretto scarto il file
    if (!isJpgOrPng) {
      this.imageList = [];
      this.onError("L'immagine deve essere di tipo JPG, PNG o JPEG")
      return false;
    } else {

      // Trasformo l'immagina caricata in base64 e lo salvo nel form
      const reader = new FileReader();
      reader.onload = () => {
        this.alertVisible = false;
        this.copertina = reader.result as string;
        this.formNews.patchValue({ copertina: file.name });
      }
      reader.readAsDataURL(file);

      return false;
    }
  };

  removeImageUpload = (file: any): boolean => {
    // Rimuovo il file dal form
    this.copertina = '';
    this.formNews.patchValue({ copertina: '' });
    this.imageList = [];
    return false;
  }

  onEliminaImmagine() {
    // Creo il form per l'eliminazione dell'immagine
    let modal = this.modal.create({
      nzTitle: 'Conferma',
      nzContent: EliminaImmagginiNewsComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {
        nome_immagine: this.news?.copertina,
        id_news: this.news?.id
      },
      nzFooter: null,
      nzCentered: true,
      nzWidth: 750,
      nzBodyStyle: { 'padding': '0px' }
    });

    // Quando chiuso il modal procedo
    modal.afterClose.subscribe((esito: boolean) => {
      // Se l'immagine è stata rimossa, rimuovo la copertina
      if (esito) {
        this.news!.copertina = '';
      }
    })
  }

  onError(message: string) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = 'error';
    this.loading = false;
  }

  onSubmit() {

    // Formo l'oggetto per la richiesta dell'api
    let news = {
      titolo: this.formNews.get('titolo')?.value,
      descrizione: this.formNews.get('descrizione')?.value,
      data: this.datePipe.transform(this.formNews.get('data')?.value, 'yyyy-MM-dd'),
      copertina: this.formNews.get('copertina')?.value,
      copertina_file: this.copertina || '',
    }

    this.loading = true;

    // Aggiorno la news nel db
    this.apiService.updateNews(this.id, news).subscribe({
      next: (data) => this.apiService.setNews(),
      error: (err) => this.onError(err.error.message),
      complete: () => {
        this.loading = false;
        this.router.navigate(['/backoffice/home/news']);
      }
    })

  }


}
