<div class="container">
    <div class="row d-flex justify-content-center align-items-center" style="padding: 20px 0px; height: 100vh;">
        <div class="col-md-6">
            <form [formGroup]="formLogin" (ngSubmit)="onSubmit()">
                <div class="px-5 py-5">
                    <div class="form-image">
                        <img src="../../../assets/images/logo_scuro.png" alt="logo Seven Live Web TV">
                    </div>
                    <div class="form-data">
                        <div class="error-message mt-3" *ngIf="error">
                            <p class="alert alert-danger">Credenziali errate, riprovare!</p>
                        </div>
                        <div class="form-input mt-3">
                            <nz-form-item class="flex-column mb-3">
                                <nz-form-label nzRequired class="text-start">Username</nz-form-label>
                                <nz-form-control>
                                    <input nz-input nzSize="large" formControlName="username" required/>
                                </nz-form-control>
                            </nz-form-item>
                            <div class="error-messages mt-2">
                                <ng-container *ngFor="let error of error_messages.username">
                                    <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formLogin.get('username')?.hasError(error.type) && (formLogin.get('username')?.dirty || formLogin.get('username')?.touched)"></nz-alert>
                                </ng-container>
                            </div>
                        </div>
                        <div class="form-input">
                            <nz-form-item class="flex-column mb-3">
                                <nz-form-label nzRequired class="text-start">Password</nz-form-label>
                                <nz-form-control>
                                    <nz-input-group [nzSuffix]="passwordTemplate" nzSize="large">
                                        <input [type]="hide ? 'text' : 'password'" nz-input formControlName="password" required />
                                    </nz-input-group>
                                    <ng-template #passwordTemplate>
                                        <span nz-icon [nzType]="hide ? 'eye-invisible' : 'eye'" (click)="hide = !hide"></span>
                                    </ng-template>
                                </nz-form-control>
                            </nz-form-item>
                            
                            <div class="error-messages mt-2">
                                <ng-container *ngFor="let error of error_messages.password">                                    
                                    <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formLogin.get('password')?.hasError(error.type) && (formLogin.get('password')?.dirty || formLogin.get('password')?.touched)"></nz-alert>
                                </ng-container>                                
                            </div>
                        </div>
                        <div class="mb-3 mt-3">
                            <button class="home-button w-100" nzType="primary" nz-button nzSize="large" type="submit" [disabled]="!formLogin.valid">Login</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>