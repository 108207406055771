<div class="container-fluid">
  <div class="row no-gutter">
    <div class="col-lg-6 d-none d-lg-flex bg-image"></div>

    <div class="col-lg-6 bg-light">
      <div class="conferma d-flex align-items-center py-5">

        <div class="container">
          <div class="row">
            <div class="col mx-auto text-center">

              <video *ngIf="!error" loop autoplay muted style="width: 200px; mix-blend-mode: multiply;">
                <source src="../../../assets/videos/success.mp4" type="video/mp4">
              </video>
              <video *ngIf="error" loop autoplay muted style="width: 200px; mix-blend-mode: multiply;">
                <source src="../../../assets/videos/error.mp4" type="video/mp4">
              </video>

              <h3 class="fs-5" *ngIf="!error">La tua mail è stata confermata con successo, ora puoi accedere alla tua
                area personale
              </h3>

              <h3 class="fs-5" *ngIf="error">Un'errore è stato riscontrato nella conferma della tua email. Riprova tra
                qualche minuto oppure contattaci a <a href="mailto:webtv@7live.tv">webtv&#64;7live.tv</a>
              </h3>

              <div class="mt-5">
                <button nz-button type="button" nzType="primary" class="bottone" routerLink="/login">{{error ? 'Torna al Login' : 'Accedi' }}</button>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>
