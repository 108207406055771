<div class="container">
  <h4 class="mb-4">Aggiorna i tuoi dati</h4>
  <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" [ngStyle]="{ marginBottom: '12px' }" *ngIf="alertVisible"></nz-alert>
  <form [formGroup]="formCliente" (ngSubmit)="onSubmit()">
    <div class="card">
      <div class="form-data">
        <div class="row">
          <div class="col-lg-6">
            <div class="forms-inputs mb-3"> <span>* Cognome</span> <input nz-input placeholder="Rossi" formControlName="cognome"></div>
          </div>
          <div class="col-lg-6">
            <div class="forms-inputs mb-3"> <span>* Nome</span> <input nz-input placeholder="Mario" formControlName="nome"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="forms-inputs mb-3"> <span>* Data di nascita</span>
              <nz-date-picker name="data_nascita" formControlName="data_nascita" nzFormat="dd/MM/yyyy"></nz-date-picker>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="forms-inputs mb-3"> <span>Luogo di nascita <i>(opzionale)</i></span> <input nz-input placeholder="Torino" formControlName="luogo_nascita"></div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="forms-inputs mb-3"> <span>* Email</span> <input nz-input placeholder="email@email.com" type="text" type="email" formControlName="email"></div>
          </div>          
        </div>        
        <div class="row">
          <div class="col-lg-6">
            <div class="forms-inputs mb-3"> <span>Telefono <i>(opzionale)</i></span> <input nz-input formControlName="telefono"></div>
          </div>          
          <div class="col-lg-6">
            <div class="forms-inputs mb-3"> <span>Codice fiscale <i>(opzionale)</i></span> <input nz-input formControlName="codice_fiscale"></div>
          </div>
        </div>
        <h4 class="mt-4 mb-4">Aggiorna la tua password</h4>
        <div class="row">
          <div class="col-lg-6">
            <div class="forms-inputs mb-3">
              <span>* Nuova password</span>
              <nz-input-group [nzSuffix]="suffixTemplate">
                <input [type]="hide ? 'text' : 'password'" nz-input formControlName="password" placeholder="Nuova password" autocomplete />
              </nz-input-group>
              <ng-template #suffixTemplate> <span nz-icon [nzType]="hide ? 'eye-invisible' : 'eye'" (click)="hide = !hide"></span></ng-template>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="forms-inputs mb-3">
              <span>* Conferma password</span>
              <nz-input-group [nzSuffix]="suffixTemplateConfirm">
                <input [type]="hide_confirm ? 'text' : 'password'" nz-input formControlName="confirm_password" placeholder="Conferma password" autocomplete />
              </nz-input-group>
              <ng-template #suffixTemplateConfirm> <span nz-icon [nzType]="hide_confirm ? 'eye-invisible' : 'eye'" (click)="hide_confirm = !hide_confirm"></span></ng-template>
            </div>
          </div>
        </div>     
        <div class="row">
          <div class="col">
            <label nz-checkbox formControlName="newsletter" class="checkbox">Accetto di ricevere mail dalla NewsLetter</label> 
          </div>
        </div>                                                 
        <div class="row mt-4">
          <div class="col-lg-6 text-start">
            <button class="bottone bg-danger w-100" nz-popconfirm nzPopconfirmTitle="Sei sicuro di voler eliminare il tuo profilo? Eliminerai anche i tuoi ticket e il tuo abbonamento!" (nzOnConfirm)="onElimina()" nz-button nzSize="large" nzType="primary" type="button" [nzLoading]="loading">ELIMINA PROFILO</button>
          </div>          
          <div class="col-lg-6 text-end mt-3 mt-lg-0 ">
            <button class="bottone w-100" nzType="primary" nz-button type="submit" nzSize="large" [nzLoading]="loading">SALVA MODIFICHE</button>
          </div>          
        </div>
      </div>
    </div>
  </form>
</div>