<div class="container">

  <!-- LOGO MOBILE -->
  <div class="d-flex justify-content-center py-md-3 py-5 d-lg-none d-block">
    <img class="" src="../../../assets/images/Logo bianco.png" height="40px" alt="">
  </div>
  <!-- LOGO MOBILE -->
</div>

<!-- LIVE -->
<div class="liveContainer d-flex align-items-center mb-5">
  <div class="gradiente"></div>
  <div class="container">
    <div class="row mb-3">
      <h3 class="text-white display-5 fw-bold">In Evidenza</h3>
    </div>
    <!-- <app-player-live-home [id]="0"></app-player-live-home> -->
    <app-player-evidenza></app-player-evidenza>

    <div class="scroll-cta d-none d-lg-block">
      <i class="bi bi-arrow-down"></i>      
    </div>
    <div class="hex-transition d-none d-lg-block"></div>
  </div>
</div>
<!-- LIVE -->

<div class="container pb-5">
  <!-- EVIDENZA -->
  <!-- <div class="evidenzaContainer">
    <div class="row mb-3">
      <h3 class="text-white display-5 mb-3 fw-bold">In evidenza</h3>
      <app-player-evidenza></app-player-evidenza>
    </div>
  </div> -->
  <!-- EVIDENZA -->

  <!-- LOADER -->
  <div class="row mt-5" *ngIf="loading">
    <div class="col text-center">
      <app-video-loading></app-video-loading>
    </div>
  </div>
  <!-- LOADER -->

  <!-- CANALI IN EVIDENZA -->
  <div class="canali-evidenza mt-5" *ngIf="canali_evidenza.length > 0 && !loading" [@fadeInOut]>
    <div class="row mb-3">
      <h3 class="text-white display-5 fw-bold">Canali in primo piano</h3>
    </div>
    <div class="row mt-3">
      <div class="col position-relative">
        <app-galleria-canali [canali]="canali_evidenza" [evidenza]="true"></app-galleria-canali>
      </div>
    </div>
  </div>
  <!-- CANALI IN EVIDENZA -->

  <!-- TUTTI I CANALI -->
  <div class="canali" *ngIf="!loading" [@fadeInOut]>
    <div class="row mt-5">
      <h3 class="text-white display-5 fw-bold">Tutti i canali</h3>
    </div>
    <div class="row mt-3" *ngIf="canali.length <= 0">
      <div class="col">
        <h3 class="mt-3 d-flex justify-content-center align-items-center">Nessun canale caricato</h3>
      </div>
    </div>
    <div class="row mt-3" *ngIf="canali.length > 0">
      <div class="col position-relative">
        <app-galleria-canali [canali]="canali"></app-galleria-canali>
      </div>
    </div>
  </div>
  <!-- TUTTI I CANALI -->

  <!-- CATEGORIE ON DEMAND -->
  <div class="categorie-ondemand" *ngIf="!loading" [@fadeInOut]>
    <div class="ondemands" *ngFor="let ondemand of ondemands">
      <div class="check" *ngIf="ondemand.video.length > 0" [@fadeInOut]>
        <div class="row mt-5">
          <h3 class="text-white display-5 fw-bold">
            {{ondemand.categoria == 'Generale' ? 'Guarda anche' : ondemand.categoria }}
          </h3>
        </div>
        <div class="row mt-3">
          <div class="col">
            <app-galleria-video [ondemands_home]="ondemand"></app-galleria-video>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- CATEGORIE ON DEMAND -->

</div>
<!-- BANNER INFO -->
<div class="info my-5 position-relative">
  <div class="container">
    <div class="row">
      <div class="col-md-6 my-auto d-flex justify-content-center flex-column">      
        <h2 class="text-white fs-2 mb-3">
          Guarda tutto ciò che desideri, <br> quando lo vuoi tu
        </h2>
        <div class="abbonamento" *ngIf="abbonamento">
          <p>Visita l'area On Demand per guardare tutti i nostri video disponibili!</p>
          <button class="bottone mt-4" nzType="primary" nz-button [routerLink]="'/ondemand'">ON DEMAND</button>
        </div>
        <div class="no-abbonamento" *ngIf="!abbonamento">
          <p>Abbonati ora a Seven Live Web TV premendo il pulsante sottostante o accedendo alla tua area personale!
          </p>
          <button class="bottone mt-4" nzType="primary" nz-button
            [routerLink]="user?.id ? '/profilo/abbonamento' : '/login'">ABBONATI</button>
        </div>      
      </div>
      <div class="col-md-6 sfondo"> </div>
    </div>
  </div>
</div>
<!-- BANNER INFO -->

<!-- NEWS -->
<div class="container mt-5" *ngIf="notizie.length > 0">
  <div class="row">
    <div class="col">
      <h3 class="text-white display-5 fw-bold">News</h3>
    </div>
  </div>

  <app-notizia *ngFor="let notizia of notizie.slice(0,5); let i = index" [notizia]="notizia" [notizie]="notizie"
    [index]="i"></app-notizia>

  <button routerLink="/news" *ngIf="notizie.length > 5" class="bottone mt-4 d-block mx-auto" nzType="primary"
    nz-button>Vedi tutte</button>
</div>
<!-- NEWS -->