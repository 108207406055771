import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { StorageService } from 'src/app/shared/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router, private storageService: StorageService) { }

  formLogin!: FormGroup;
  hide: boolean = false;

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  ngOnInit(): void {
    this.formLogin = new FormGroup({
      email: new FormControl(null),
      password: new FormControl(null),
    });
  }

  onError(message: string) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = 'error';
    this.loading = false;
  }

  onLogin() {
    const email = this.formLogin.get('email')?.value;
    const password = this.formLogin.get('password')?.value;

    this.apiService.login(email, password).subscribe({
      next: (data) => {
        this.storageService.setUser(data.data);
        this.storageService.setToken(data.token);
      },
      error: (err) => this.onError(err.error.message),
      complete: () => {
        this.router.navigate(['/home'])
      }

    })
  }

}
