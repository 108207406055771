<div id="wrapper">
  <ul nz-menu *ngIf="!collapse" nzMode="inline" class="border border-top-0" id="menu">
    <div id="logo" class="my-4 mx-auto"></div>
    <li nz-menu-item  routerLink="/backoffice/home/dashboard" routerLinkActive="active text-white">      
      <span nz-icon nzType="home" nzTheme="outline"></span>
      <span>Home</span>
    </li>
    <li nz-submenu routerLinkActive="nzOpen" #rla1="routerLinkActive" [nzOpen]="rla1.isActive" nzTitle="Gestione Video" nzIcon="video-camera">
      <ul>
        <li nz-menu-item [routerLink]="user?.tipo == 'Amministratore' ? '/backoffice/home/canali' : '/backoffice/home/canali/' + user?.id_canale" routerLinkActive="active text-white">{{ user?.tipo == 'Amministratore' ? 'Canali' : 'Canale' }}</li>   
        <li nz-menu-item routerLink="/backoffice/home/categorie" nzMatchRouter *ngIf="user?.tipo == 'Amministratore'">Categorie</li>   
        <li nz-menu-item routerLink="/backoffice/home/ondemand" nzMatchRouter>On Demand</li>
        <li nz-menu-item routerLink="/backoffice/home/live" nzMatchRouter>Live</li>
      </ul>
    </li>
    <li nz-submenu routerLinkActive="nzOpen" #rla2="routerLinkActive" [nzOpen]="rla2.isActive" nzTitle="Gestione Clienti" nzIcon="user" *ngIf="user?.tipo == 'Amministratore'">
      <ul>
        <li nz-menu-item routerLink="/backoffice/home/clienti" nzMatchRouter>Clienti</li>
        <li nz-menu-item routerLink="/backoffice/home/abbonamenti" nzMatchRouter>Abbonamenti</li>
        <li nz-menu-item routerLink="/backoffice/home/tickets" nzMatchRouter>Tickets</li>
      </ul>
    </li>     
    <li nz-menu-item routerLink="/backoffice/home/news" routerLinkActive="active text-white" *ngIf="user?.tipo == 'Amministratore'">
      <span nz-icon nzType="notification" nzTheme="outline"></span>
      <span>News</span>
    </li>
    <li nz-menu-item routerLink="/backoffice/home/utenti" routerLinkActive="active text-white" *ngIf="user?.tipo == 'Amministratore'">
      <span nz-icon nzType="user" nzTheme="outline"></span>
      <span>Utenti</span>
    </li>
    <a nz-menu-item target="_blank" [href]="url" nz-menu-item class="text-decoration-none link-menu">      
      <span nz-icon nzType="shop" nzTheme="outline"></span>
      <span>Vai alla Web TV</span>      
    </a>
    <li nz-menu-item nz-popconfirm nzPopconfirmTitle="Sei sicuro di voler effettuare il logout?." (nzOnConfirm)="onLogout()" [nzOkText]="'Esci'">      
      <span nz-icon nzType="logout" nzTheme="outline"></span>
      <span>Log-out</span>
    </li>
  </ul>
  <ng-container *ngIf="collapse">
    <div class="top-bar border-bottom px-3">
      <i class="bi bi-list fs-1 fw-bold cursor-pointer burger" type="button" data-bs-toggle="offcanvas"
        data-bs-target="#offcanvas-menu"></i>
      <img src="../../../assets/images/Logo bianco.png" alt="Logo Seven Live Web TV">
    </div>
    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvas-menu">
      <div class="offcanvas-header">        
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body p-0">        
        <ul nz-menu nzMode="inline" class="border-top-0 w-100 h-100">
          <div id="logo" class="my-4 mx-auto"></div>        
          <li nz-menu-item  routerLink="/backoffice/home/dashboard" nz-tooltip nzTooltipPlacement="right">      
            <span>Home</span>
          </li>
          <li nz-menu-item [routerLink]="user?.tipo == 'Amministratore' ? '/backoffice/home/canali' : '/backoffice/home/canali/' + user?.id_canale" routerLinkActive="active text-white">
            <span>Canali</span>
          </li>
          <li nz-menu-item routerLink="/backoffice/home/categorie" routerLinkActive="active text-white" *ngIf="user?.tipo == 'Amministratore'">
            <span>Categorie</span>
          </li>
          <li nz-menu-item routerLink="/backoffice/home/ondemand" routerLinkActive="active text-white">
            <span>On Demand</span>
          </li>   
          <li nz-menu-item routerLink="/backoffice/home/live" routerLinkActive="active text-white">
            <span>Live</span>
          </li>           
          <li nz-menu-item routerLink="/backoffice/home/clienti" routerLinkActive="active text-white" *ngIf="user?.tipo == 'Amministratore'">
            <span>Clienti</span>
          </li>
          <li nz-menu-item routerLink="/backoffice/home/abbonamenti" routerLinkActive="active text-white" *ngIf="user?.tipo == 'Amministratore'">
            <span>Abbonamenti</span>
          </li>
          <li nz-menu-item routerLink="/backoffice/home/tickets" routerLinkActive="active text-white" *ngIf="user?.tipo == 'Amministratore'">
            <span>Tickets</span>
          </li>
          <li nz-menu-item routerLink="/backoffice/home/news" routerLinkActive="active text-white" *ngIf="user?.tipo == 'Amministratore'">
            <span>News</span>
          </li>
          <li nz-menu-item routerLink="/backoffice/home/utenti" routerLinkActive="active text-white" *ngIf="user?.tipo == 'Amministratore'">
            <span>Utenti</span>
          </li>
          <a nz-menu-item target="_blank" [href]="url" nzMatchRouter nz-menu-item class="text-decoration-none link-menu">            
            <span>Vai alla Web TV</span>            
          </a>
          <li nz-menu-item nz-popconfirm nzPopconfirmTitle="Sei sicuro di voler effettuare il logout?." (nzOnConfirm)="onLogout()" [nzOkText]="'Esci'">            
            <span>Log-out</span>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>
  <div id="contenuto" class="container my-auto content">
    <router-outlet></router-outlet>
  </div>
</div>