<div class="container">

  <!-- LOGO MOBILE -->
  <div class="d-flex justify-content-center py-5 d-lg-none d-block">
    <img src="../../assets/images/Logo bianco.png" height="40px" alt="Logo Seven Live">
  </div>
  <!-- LOGO MOBILE -->

</div>
<!-- LIVE  -->
<div class="liveContainer d-flex align-items-center mb-5">
  <!-- BG GRADIENT  -->
  <div class="gradiente"></div>
  <!-- BG GRADIENT  -->
  <div class="container">
    <div class="row mb-3">

      <h3 class="display-5 text-white fw-bold">Live TV</h3>
      <p class="text-white">Sfoglia il palinsesto dei canali live</p>
    </div>
    <app-player-evidenza></app-player-evidenza>
  </div>
</div>
<!-- LIVE  -->