import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ApiService } from 'src/app/shared/api.service';

@Component({
  selector: 'app-modal-aggiungi-categoria-backoffice',
  templateUrl: './modal-aggiungi-categoria-backoffice.component.html',
  styleUrls: ['./modal-aggiungi-categoria-backoffice.component.css']
})
export class ModalAggiungiCategoriaBackofficeComponent implements OnInit {

  constructor(private apiService: ApiService) { }

  readonly #modal = inject(NzModalRef);

  formCategoria!: FormGroup;

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formCategoria = new FormGroup({
      titolo: new FormControl(null),
      descrizione: new FormControl(null),
      home: new FormControl(null)
    })
  }

  onClose() {
    this.#modal.destroy(false)
  }

  onError(message: string) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = 'error';
    this.loading = false;
  }

  onSubmit() {

    // Creo l'oggetto per la richiesta dell'api
    let categoria = {
      titolo: this.formCategoria.get('titolo')?.value,
      descrizione: this.formCategoria.get('descrizione')?.value,
      home: this.formCategoria.get('home')?.value || false
    }

    this.loading = true;

    // Aggiungo la categoria al db
    this.apiService.addCategoria(categoria).subscribe({
      next: (data) => this.apiService.setCategorie(),
      error: (err) => this.onError(err.error.message),
      complete: () => {
        this.loading = false;
        this.#modal.destroy(true)
      }
    })

  }

}
