<div class="p-3">
    <ng-template #ticketsTitle>        
        <div class="row p-md-3 p-1 align-items-center">
            <div class="col-md-8">
                <h3 class="text-white mb-0">Tickets</h3>
            </div>
            <div class="col-md-4">
                <button nz-button nzType="primary" nzSize="large" (click)="onAdd()" class="home-button w-100">Aggiungi ticket</button>
            </div>
        </div>        
        <div class="row p-md-3 p-1">            
            <div class="col-md-6">                        
                <input nz-input placeholder="Cerca Cliente ticket" nzSize="large" [(ngModel)]="clienteFilter" />
            </div>              
            <div class="col-md-6">                        
                <input nz-input placeholder="Cerca Stato ticket" nzSize="large" [(ngModel)]="statoFilter" />                
            </div>                                   
        </div>
    </ng-template>
    <nz-card [nzTitle]="ticketsTitle">
        <div class="row p-md-3 p-1">
            <nz-table #ticketsTable nzShowPagination class="tabella"
                [nzTotal]="(tickets | searchClienteTicket: clienteFilter | searchStatoTicket: statoFilter).length"
                nzShowSizeChanger [nzPageSize]="10"
                [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
                [nzData]="tickets | searchClienteTicket: clienteFilter | searchStatoTicket: statoFilter"
                [nzBordered]="true" [nzNoResult]="'Nessun ticket trovato'">
                <thead>
                    <tr>
                        <th [nzSortFn]="onOrdinamentoTitolo">Titolo</th>
                        <th [nzSortFn]="onOrdinamentoCategoria" class="text-start">Categoria</th>
                        <th [nzSortFn]="onOrdinamentoCliente" class="text-start">Cliente</th>
                        <th [nzSortFn]="onOrdinamentoStato" class="text-center">Stato</th>
                        <th class="text-center">Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let ticket of ticketsTable.data| searchClienteTicket: clienteFilter | searchStatoTicket: statoFilter">
                        <td>{{ticket.titolo}}</td>
                        <td class="text-start">{{ticket.categoria}}</td>
                        <td class="text-start">{{ticket.cliente}}</td>
                        <td class="text-center">{{ticket.stato}}</td>
                        <td class="text-center">
                            <nz-button-group>                                
                                <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
                                    <span nz-icon nzType="ellipsis"></span>
                                </button>
                          </nz-button-group>
                          <nz-dropdown-menu #menu="nzDropdownMenu">
                            <ul nz-menu>
                              <li nz-menu-item class="p-3" (click)="onEdit(ticket.id)">Modifica ticket</li>                              
                              <li nz-menu-item class="p-3" (click)="onClose(ticket.id)" *ngIf="ticket.stato != 'Chiuso'">Chiudi ticket</li>
                              <li nz-menu-item class="p-3" (click)="onElimina(ticket.id)">Elimina ticket</li>
                            </ul>
                          </nz-dropdown-menu>
                        </td>
                    </tr>
                </tbody>
            </nz-table>            
        </div>
    </nz-card>
</div>