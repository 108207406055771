<div class="news">

    <div class="row mt-3">
        <div class="col-md-3 my-auto">
            <img class="copertina" #img
                [src]="notizia.copertina_file.length > 0 ? notizia.copertina_file : '../../../assets/images/default_copertina.png'"
                (error)="img.src = '../../../assets/images/default_copertina.png'" alt="">
        </div>
        <div class="col-md-9 my-auto">
            <span class="badge px-2 mt-3 shadow-1-strong">{{notizia.data_creazione | date: 'dd/MM/yyyy'}}</span>
            <div class="titolo-news">
                <h4 class="d-inline d-md-block mt-3 text-white"><strong>{{notizia.titolo}}</strong></h4>
            </div>
            <p [ngStyle]="{'-webkit-line-clamp': notizia.espandi ? '99' : '3'}"
                [ngClass]="{'descrizione-news':notizia.descrizione.length > 400 && !notizia.espandi}"
                class="news-desc text-muted">
                {{ (notizia.descrizione.length > 400 && !notizia.espandi ) ? ( notizia.descrizione | slice: 0 : 400) + ' ...' : notizia.descrizione }}
            </p>
            <span class="d-md-inline text-white" *ngIf="notizia.descrizione.length > 400">
                <a (click)="onExpand(notizia)"
                    class="show-more-news">{{!notizia.espandi ? "Mostra di più" : "Mostra di meno"}}</a>
            </span>
        </div>
    </div>
    <hr *ngIf="(index + 1) <= 5 && (index + 1) < notizie.length" class="my-4">
</div>