<div class="p-3">
    <ng-template #ticketsTitle>
        <h3 class="text-white mb-0">Aggiungi Ticket</h3>
    </ng-template>    
    <nz-card [nzTitle]="ticketsTitle" [nzActions]="[actionClose, actionAdd]" class="mt-5 mb-5">
        <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" [ngStyle]="{ marginBottom: '12px' }" *ngIf="alertVisible"></nz-alert>
        <form [formGroup]="formTicket" (ngSubmit)="onSubmit()" id="formTicket">                        
            <div class="row">
                <div class="col-md-6">
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Categoria</nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearch nzAllowClear formControlName="categoria" nzSize="large" class="w-100" required>
                                <nz-option nzValue="Profilo" nzLabel="Profilo"></nz-option>
                                <nz-option nzValue="Abbonamento" nzLabel="Abbonamento"></nz-option>
                                <nz-option nzValue="Live" nzLabel="Live"></nz-option>
                                <nz-option nzValue="On Demand" nzLabel="On Demand"></nz-option>
                                <nz-option nzValue="Contatti" nzLabel="Contatti"></nz-option>
                                <nz-option nzValue="Altro" nzLabel="Altro"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div class="col-md-6">
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Titolo</nz-form-label>
                        <nz-form-control>
                            <input nz-input nzSize="large" formControlName="titolo" required />                                     
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>            
            <div class="row">
                <div class="col">
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Messaggio</nz-form-label>
                        <nz-form-control>
                            <textarea nz-input nzSize="large" formControlName="messaggio" required></textarea>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>      
            <div class="row">
                <div class="col">
                    <nz-form-item class="flex-column mb-3">
                        <nz-form-label nzRequired class="text-start">Cliente</nz-form-label>
                        <nz-form-control>
                            <nz-select nzShowSearch nzAllowClear formControlName="cliente" nzSize="large" class="w-100" (nzOnSearch)="onSeleziona($event)" required>
                                <nz-option *ngFor="let cliente of clienti" [nzValue]="cliente" [nzLabel]="cliente.cognome + ' ' + cliente.nome + ' | ' + cliente.email"></nz-option>
                            </nz-select>  
                        </nz-form-control>
                    </nz-form-item>                     
                </div>
            </div>          
        </form>
    </nz-card>    

    <ng-template #actionClose>
        <button nz-button nzSize="large" type="button" (click)="onClose()">Annulla</button>        
    </ng-template>
    <ng-template #actionAdd>
        <button nz-button nzSize="large" [nzLoading]="loading" type="submit" class="home-button" form="formTicket">Aggiungi</button>        
    </ng-template>
    
</div>