import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { style, animate, transition, trigger } from '@angular/animations';
import Hls from "hls.js"
import { ApiService } from 'src/app/shared/api.service';
import { Live } from 'src/app/shared/interfacce.model';

@Component({
  selector: 'app-player-evidenza',
  templateUrl: './player-evidenza.component.html',
  styleUrls: ['./player-evidenza.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class PlayerEvidenzaComponent implements OnInit {

  
  @ViewChild('videoPlayer', { static: true }) videoPlayer?: ElementRef;
  video!: HTMLVideoElement;

  /* @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == 'MediaPlayPause' || event.key == '') {
      event.preventDefault();
      event.stopPropagation();
    }
  } */

  @HostListener('document:mousemove', ['$event'])
  onMouseStop(e: MouseEvent) {

    let doc: any = window.document;
    this.isFScreen = !doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement ? false : true;

    clearTimeout(this.timer);

    if (this.isFScreen) {

      let cursor = document.body.style.cursor;

      if (cursor == 'none') {
        document.body.style.cursor = "auto";
        let controlli = document.getElementById('controls');
        controlli?.classList.remove('fullscreen');
      }

      this.timer = setTimeout(function () {

        if (cursor == '' || cursor == 'auto') {
          document.body.style.cursor = "none";
          let controlli = document.getElementById('controls');
          controlli?.classList.add('fullscreen');
        }

      }, 3000);

    } else {
      let cursor = document.body.style.cursor;

      if (cursor == 'none') {

        document.body.style.cursor = "auto";
        let controlli = document.getElementById('controls');
        controlli?.classList.remove('fullscreen');
      }
    }
  }

  constructor(private apiService: ApiService, private deviceService: DeviceDetectorService) { }

  lives: Live[] = [];
  index: number = 0;
  currentVideo: { src: string, poster: string, durata: number, titolo: string, id_canale: number, canale: string } = { src: '', poster: '', durata: 0, titolo: '', id_canale: 0, canale: '' };
  

  url: string = environment.apiUrl;

  durata: number = 0;
  volume: number = 100;
  timer: any = 0;

  currentTime: number = 0;

  visibile: boolean = false;
  isFScreen: boolean = false;
  loading: boolean = true;
  controlli: boolean = false;
  carica: boolean = true;

  source: any;

  hls: Hls | undefined = undefined;

  resumeVideos: any[] = [];

  ngOnInit(): void {

    this.video = this.videoPlayer?.nativeElement;

    let resumeLocalData = localStorage.getItem('resumeVideos');
    if (resumeLocalData) {
      this.resumeVideos = JSON.parse(resumeLocalData);
    }

    this.getData();
  }

  getData() {
    this.visibile = false;

    // Recupero le live del giorno
    this.apiService.getLivesHome().subscribe({
      next: (data) => {
        // Recupero le live raggruppate per id canale
        this.lives = [...new Map(data.map(live => [live['id_canale'], live])).values()];
      },
      error: (err) => this.apiService.error("<p><strong>I video live non sono stati caricati correttamente! </strong></p> <p>Ricaricare la pagina oppure aspettare qualche secondo. </p><p>In caso di errore continuo contattaci a <strong><a href='mailto:webtv@7live.tv'>webtv@7live.tv</a></strong>!</p>"),
      complete: () => {

        this.carica = false;

        if (this.lives.length > 0) {

          // Ordino l'array per il campo orario inizio
          // this.lives.sort(function (a, b) {
          //   return a.id_canale - b.id_canale;
          // });

          // Controllo se ci sono ancora live per questo canale
          let live_canale = this.lives.filter(live => live.id_canale == this.currentVideo.id_canale);          

          // Se è stato selezionato un canale e ci sono ancora live, continuo a far vedere live di questo canale          
          if (this.currentVideo.id_canale != 0 && live_canale.length > 0) {

            // Setto il current video con i dati del live che recupero
            this.currentVideo = { src: live_canale[0].cloudflare_video_url, poster: live_canale[0].cloudflare_image_url, durata: live_canale[0].durata, titolo: live_canale[0].titolo, id_canale: live_canale[0].id_canale, canale: live_canale[0].canale };

            // Recupero i dati per far partire l'hls
            this.getDataVideo(live_canale[0])

          } else {
            // Setto il current video con i dati del primo live che recupero
            this.currentVideo = { src: this.lives[0].cloudflare_video_url, poster: this.lives[0].cloudflare_image_url,  durata: this.lives[0].durata, titolo: this.lives[0].titolo, id_canale: this.lives[0].id_canale, canale: this.lives[0].canale };

            // Recupero i dati per far partire l'hls
            this.getDataVideo(this.lives[0])
          }

          this.getTimeVideo();
        }


      }

    })

  }

  getDataVideo(live: Live) {
    
    this.loading = true;
    this.visibile = true;

    // Seleziono come selezionata la live
    live.selected = true;

    // Recupero le informazioni e le imposto per il live in esposizione    
    this.currentVideo = { src: live.cloudflare_video_url, poster: live.cloudflare_image_url, durata: live.durata, titolo: live.titolo, id_canale: live.id_canale, canale: live.canale };

    // Muto il video
    //this.video.muted = true;

    // Se l'HLS è supportato procedo
    if (Hls.isSupported()) {

      if (this.hls)
        this.hls.destroy();

      this.hls = new Hls();

      // Creo una nuova connessione e carico il video       
      this.hls.loadSource(this.currentVideo.src);
      this.hls.attachMedia(this.video);

      // Controllo gli eventi di Errore
      this.hls.on(Hls.Events.ERROR, (event, { details }) => {

        // Se il video è finito recupero gli altri live
        if (details == "manifestParsingError") {

          this.loading = false;
          this.controlli = false;

          this.getData();

        }
        // Se il video è in buffer mostro il caricamento
        else if (details == 'bufferNudgeOnStall' || details == 'bufferStalledError') {
          this.loading = true;
        }
      })

      // Controllo gli eventi dei Frammenti, se è caricato tolgo il caricamento
      this.hls.on(Hls.Events.FRAG_BUFFERED, () => {
        this.loading = false;
      });

    } else if (this.videoPlayer?.nativeElement.canPlayType('application/vnd.apple.mpegurl')) {
      this.source = document.getElementById('videoSource') as HTMLElement;
      this.source.src = this.currentVideo.src;
    } else {
      console.error('HLS is not supported in this browser.');
    }

    // Carico il video
    this.video.load();

    // Setto durata, volume per il player
    this.video.onloadedmetadata = (e: any) => {
      let video = e.target
      this.durata = video.duration * 1000;
      this.volume = video.volume * 100;

      if (this.visibile) {
        this.controlli = true;
        this.loading = false;
        this.video.play();
      }
    }

    // Se il video è finito, recupero gli altri live
    this.video.onended = (e: any) => {
      this.getData();
    }
  }


  getTimeVideo() {

    // Controllo se il video che sto guardando è già stato aperto
    let resumeVideo = this.resumeVideos.filter(live => live.id_canale == this.currentVideo.id_canale);

    
    // Se ho un risultato procedo
    if (resumeVideo.length > 0) {
      
      // Setto il timestamp se il minutaggio salvato è maggiore di 5 secondi
      if (this.currentVideo.durata - resumeVideo[0].timeStamp > 5) {
        // Setto il current time del video al timestamp salvato
        this.video.currentTime = resumeVideo[0].timeStamp;
      }      
      
    }


    setInterval(() => {
      // Recupero canale e timestamp del video che sto guardando
      let cVideo = { id_canale: this.currentVideo.id_canale, timeStamp: this.video.currentTime };

      // Se non ci sono video nell'array lo pusho
      if (this.resumeVideos.length == 0) {
        this.resumeVideos.push(cVideo)
      } else {

        // Se ci sono video nell'array controllo se già esiste se no lo pusho, se si aggiorno il timestamp
        const alreadyExists = this.resumeVideos.find((video) => video.id_canale === cVideo.id_canale);
        if (!alreadyExists) {
          this.resumeVideos.push(cVideo);
        } else {
          alreadyExists.timeStamp = cVideo.timeStamp;
        }
      }
      
      // Salvo nel localstorage il tempo del video
      localStorage.setItem('resumeVideos', JSON.stringify(this.resumeVideos));

    }, 5000)
  }

  onVideoChange(live: Live) {
    // Recupero il vecchio video prima di cambiarlo
    let oldVideo = { id_canale: this.currentVideo.id_canale, timeStamp: this.video.currentTime };

    // Se non ci sono video nell'array lo pusho
    if (this.resumeVideos.length == 0) {
      this.resumeVideos.push(oldVideo)
    } else {
      // Se ci sono video nell'array controllo se già esiste se no lo pusho, se si aggiorno il timestamp del vecchio video
      const alreadyExists = this.resumeVideos.find((video) => video.id_canale === oldVideo.id_canale);
      if (!alreadyExists) { this.resumeVideos.push(oldVideo); } else { alreadyExists.timeStamp = oldVideo.timeStamp; }
    }

    // Salvo nel localstorage il tempo del video
    localStorage.setItem('resumeVideos', JSON.stringify(this.resumeVideos));

    // Se il live non è stato selezionato procedo
    if (!live.selected) {

      // Muto il video
      //this.video.muted = true;

      // Recupero i dati del video
      this.getDataVideo(live);

      // Ciclo per le live per cambiare il flag 'selected'
      for (let _live of this.lives) {

        // Se l'id è diverso lo setto come non selezionato altrimenti lo seleziono
        if (_live.id != live.id) {
          _live.selected = false;
        } else {
          _live.selected = true;
        }
      }
    }
  }

  onMute() {
    // Se il video era mutato lo smuto e viceversa.
    this.video.muted = !this.video.muted;
  }

  onVolume(volume: any) {
    this.video.volume = volume.target.valueAsNumber / 100;
    this.volume = volume.target.valueAsNumber;
    this.volume < 1 ? this.video.muted = true : this.video.muted = false;
  }

  onFullScreen() {

    if (this.deviceService.os == 'iOS') {
      let videoPlayer: any = document.getElementById('video');
      videoPlayer.webkitEnterFullScreen();
    }

    let doc: any = window.document;
    let docEl: any = document.getElementById('player');

    let requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.webkitbeginfullscreen || docEl.msRequestFullscreen;
    let cancelFullScreen = doc.exitFullscreen || doc?.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

    if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement && !doc.webkitbeginfullscreen) {
      requestFullScreen.call(docEl);
      this.isFScreen = true;
    }
    else {
      cancelFullScreen.call(doc);
      this.isFScreen = false;
    }
  }

  onRightClick(e: any) {
    return false;
  }

  togglePause() {
    if (this.video.paused) {
      this.video.play();
    }
    else {
      this.video.pause();
    }
  }

}
