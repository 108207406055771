import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ApiService } from 'src/app/shared/api.service';
import { Canale, Categoria, UserBackoffice } from 'src/app/shared/interfacce.model';
import { StorageService } from 'src/app/shared/storage.service';
import { VideoService } from 'src/app/shared/video.service';
import { environment } from 'src/environments/environment';
import { ModalAggiungiCategoriaBackofficeComponent } from '../../categorie-backoffice/modal-aggiungi-categoria-backoffice/modal-aggiungi-categoria-backoffice.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import Uppy from '@uppy/core';
import Tus from '@uppy/tus'

@Component({
  selector: 'app-aggiungi-ondemand-backoffice',
  templateUrl: './aggiungi-ondemand-backoffice.component.html',
  styleUrls: ['./aggiungi-ondemand-backoffice.component.css']
})
export class AggiungiOndemandBackofficeComponent implements OnInit {

  constructor(private apiService: ApiService, private storageService: StorageService, private router: Router, private videoService: VideoService, private modal: NzModalService, private viewContainerRef: ViewContainerRef) { }

  formOndemand!: FormGroup;

  canali: Canale[] = [];
  categorie: Categoria[] = [];

  video_name: string = '';
  copertina: string = '';
  imageList: NzUploadFile[] = [];

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  user!: UserBackoffice;

  url: string = environment.apiUrl;
  locale: any = require('@uppy/locales/lib/it_IT.js');
  uppy: Uppy = new Uppy({
    debug: true, locale: { strings: this.locale.default.strings }, allowMultipleUploads: false, restrictions: { maxNumberOfFiles: 1, allowedFileTypes: ['video/mp4'], },
    onBeforeFileAdded: (currentFile, files) => {
      const name = Date.now() + '_' + currentFile.name;
      this.video_name = name;
      const modifiedFile = {
        ...currentFile,
        meta: {
          ...currentFile.meta,
          name
        },
        name
      };
      return modifiedFile
    }
  });

  ngOnInit(): void {

    this.user = this.storageService.getUserBackoffice();

    this.formOndemand = new FormGroup({
      titolo: new FormControl(null),
      descrizione: new FormControl(null),
      id_canale: new FormControl(null),
      id_categoria: new FormControl(null),
      abbonamento: new FormControl(true),
      copertina: new FormControl(null),
      video: new FormControl(null)
    })

    this.apiService.getCanali().subscribe({
      next: (data) => {
        if (this.user.tipo == 'Amministratore')
          this.canali = [...data];
        else {
          this.canali = [...data.filter((canale: Canale) => canale.id == this.user.id_canale)];
          this.formOndemand.patchValue({ id_canale: [this.user.id_canale] })
          this.formOndemand.get('id_canale')?.disable();
        }
      },
      error: (err) => this.onError("Canali non caricati correttamente, ricaricare la pagina o aspettare qualche secondo!"),
      complete: () => {
        this.apiService.getCategorie().subscribe({
          next: (data) => this.categorie = [...data],
          error: (err) => this.onError("Categorie non caricati correttamente, ricaricare la pagina o aspettare qualche secondo!"),
          complete: () => {

            this.onAddVideo();

          }
        })
      }
    })

  }

  onAddVideo() {

    this.uppy.on('file-added', (file: any) => {

      this.formOndemand.patchValue({ video: this.video_name });

      this.videoService.generateThumbnail(file.data).then(thumbnailData => {
        this.copertina = thumbnailData;

        this.formOndemand.patchValue({ copertina: this.video_name.substring(0, this.video_name.lastIndexOf('.')) + '.jpg' || this.video_name + '.jpg' });
      })
    });
  }

  beforeImageUpload = (file: any): boolean => {
    this.imageList = this.imageList.concat(file);
    const isJpgOrPng = this.imageList.length > 0 ? this.imageList[0]!.type === 'image/jpeg' || this.imageList[0]!.type === 'image/png' : true;

    if (!isJpgOrPng) {
      this.imageList = [];
      this.onError("L'immagine di copertina deve essere di tipo JPG, PNG o JPEG");
      return false;
    } else {

      const reader = new FileReader();
      reader.onload = () => {
        this.alertVisible = false;
        this.copertina = reader.result as string;
        this.formOndemand.patchValue({ copertina: file.name });
      }
      reader.readAsDataURL(file);

      return false;
    }
  };

  removeImageUpload = (file: any): boolean => {
    this.copertina = '';
    this.formOndemand.patchValue({ copertina: '' });
    this.imageList = [];
    return false;
  }

  onClose() {
    this.router.navigate(['/backoffice/home/ondemand']);
  }

  onCategoria(categoria: string) {
    // Se la categoria selezionata è altro
    if (categoria == '0') {

      // Creo il modal
      let modal = this.modal.create({
        nzTitle: 'Aggiungi Categoria',
        nzContent: ModalAggiungiCategoriaBackofficeComponent,
        nzViewContainerRef: this.viewContainerRef,
        nzFooter: null,
        nzCentered: true,
        nzWidth: 1250,
        nzBodyStyle: { 'padding': '0px' }
      });

      // Se il modal viene chiuso
      modal.afterClose.subscribe(() => {

        // Recupero le categorie
        this.apiService.getCategorie().subscribe({
          next: (data) => this.categorie = data,
          error: (err) => this.onError("Categorie non caricati correttamente, ricaricare la pagina o aspettare qualche secondo!"),
          complete: () => {
            this.formOndemand.patchValue({ id_categoria: '' })
          }
        })

      })
    }
  }

  onError(message: string) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = 'error';
    this.loading = false;
  }

  onSubmit() {

    // Formo l'oggetto per la richiesta dell'api
    let ondemand = {
      titolo: this.formOndemand.get('titolo')?.value,
      descrizione: this.formOndemand.get('descrizione')?.value,
      id_canale: this.formOndemand.get('id_canale')?.value ? JSON.stringify(this.formOndemand.get('id_canale')?.value) : null,
      id_categoria: this.formOndemand.get('id_categoria')?.value ? JSON.stringify(this.formOndemand.get('id_categoria')?.value) : null,
      abbonamento: this.formOndemand.get('abbonamento')?.value || false,
      copertina: this.formOndemand.get('copertina')?.value,
      copertina_file: this.copertina,
      video: this.formOndemand.get('video')?.value,
      cloudflare_id: ''
    }

    this.loading = true;

    // Attuo i controlli per vedere se sono stati caricati tutti i campi richiesti
    if (!ondemand.video) {
      this.onError("Inserire un video per procedere!");
    } else if (!ondemand.titolo) {
      this.onError("Inserire un titolo per procedere!");
    } else if (!ondemand.id_canale) {
      this.onError("Inserire un canale per procedere!");
    } else if (!ondemand.id_categoria) {
      this.onError("Inserire una categoria per procedere!");
    } else if (!ondemand.copertina) {
      this.onError("Inserire una copertina per procedere!");
    } else if (!ondemand.copertina_file) {
      this.onError("Inserire una copertina per procedere!");
    } else {

      // Faccio partire il plugin Uppy
      this.uppy.use(Tus, { endpoint: this.url + 'video', chunkSize: 200 * 1024 * 1024 });

      // Faccio partire l'upload
      this.uppy.upload().then((result) => {

        // Se ho un risultato procedo
        if (result) {

          // Se c'è un errore lo riporto
          if (result?.failed.length > 0) {
            result.failed.forEach((file) => {
              this.onError(file.error + ' Ricaricare la pagina, per procedere al caricamento del video!');
            });

          } else {

            // Recupero l'url del video su cloudflare e il suo id
            const split = result.successful[0].response?.uploadURL?.split("/");
            const split_2 = split![4].split("?");
            const cloudflare_video_id = split_2[0];
            ondemand.cloudflare_id = cloudflare_video_id

            // Aggiungo l'ondemand al db
            this.apiService.addOndemand(ondemand).subscribe({
              next: (data) => this.apiService.setOndemand(),
              error: (err) => this.onError(err.error.message + ' Ricaricare la pagina, per procedere al caricamento del video!'),
              complete: () => {
                this.loading = false;

                // Ritorno alla lista
                this.router.navigate(['/backoffice/home/ondemand']);
              }
            })

          }
        }
      });
    }


  }
}
