<div class="p-3">
    <ng-template #liveTitle>        
        <div class="row p-md-3 p-1 align-items-center">
            <div class="col-md-8">
                <h3 class="text-white mb-0">Live</h3>
            </div>
            <div class="col-md-4">
                <button nz-button nzType="primary" nzSize="large" (click)="onAdd()" class="home-button w-100">Aggiungi live</button>             
            </div>
        </div>        
        <div class="row p-md-3 p-1">            
            <div class="col-md-6">                        
                <input nz-input placeholder="Cerca Titolo live" nzSize="large" [(ngModel)]="titoloFilter" />
            </div>              
            <div class="col-md-6">                        
                <input nz-input placeholder="Cerca Canale live" nzSize="large" [(ngModel)]="canaleFilter" />
            </div>                                   
        </div>
    </ng-template>
    <nz-card [nzTitle]="liveTitle">
        <div class="row p-md-3 p-1">
            <nz-table #liveTable nzShowPagination class="tabella"
                [nzTotal]="(live | searchTitoloLive: titoloFilter | searchCanaleLive: canaleFilter).length"
                nzShowSizeChanger [nzPageSize]="10"
                [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
                [nzData]="live | searchTitoloLive: titoloFilter | searchCanaleLive: canaleFilter"
                [nzBordered]="true" [nzNoResult]="'Nessuna live trovata'">
                <thead>
                    <tr>                        
                        <th [nzSortFn]="onOrdinamentoCanale" class="text-start">Canale</th>                        
                        <th [nzSortFn]="onOrdinamentoTitolo">Titolo</th>
                        <th class="text-center">Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let live of liveTable.data | searchTitoloLive: titoloFilter | searchCanaleLive: canaleFilter">                        
                        <td class="text-start">{{live.canale}}</td>                                                
                        <td>{{live.titolo}}</td>
                        <td class="text-center">
                            <nz-button-group>                                
                                <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
                                    <span nz-icon nzType="ellipsis"></span>
                                </button>
                            </nz-button-group>
                            <nz-dropdown-menu #menu="nzDropdownMenu">
                                <ul nz-menu>
                                    <li nz-menu-item class="p-3" (click)="onEdit(live.id)">Modifica live</li>                                      
                                    <li nz-menu-item class="p-3" (click)="onElimina(live.id)">Elimina live</li>
                                </ul>
                            </nz-dropdown-menu>
                        </td>
                    </tr>
                </tbody>
            </nz-table>            
        </div>
    </nz-card>
</div>