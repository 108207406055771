import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { Cliente, User } from 'src/app/shared/interfacce.model';
import { StorageService } from 'src/app/shared/storage.service';

@Component({
  selector: 'app-gestisci-profilo',
  templateUrl: './gestisci-profilo.component.html',
  styleUrls: ['./gestisci-profilo.component.css']
})
export class GestisciProfiloComponent implements OnInit {

  constructor(private apiService: ApiService, private datePipe: DatePipe, private storageService: StorageService, private router: Router) { }

  user?: User;
  cliente?: Cliente;

  hide: boolean = false;
  hide_confirm: boolean = false;

  formCliente!: FormGroup;

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formCliente = new FormGroup({
      email: new FormControl(null),
      nome: new FormControl(null),
      cognome: new FormControl(null),
      data_nascita: new FormControl(null),
      luogo_nascita: new FormControl(null),
      password: new FormControl(null),
      confirm_password: new FormControl(null),
      codice_fiscale: new FormControl(null),
      telefono: new FormControl(null),
      newsletter: new FormControl(null)
    });

    // Recupero l'utente loggato
    this.user = this.storageService.getUser();

    // Recupero i dati del cliente
    this.apiService.getCliente(this.user.id).subscribe({
      next: (data) => this.cliente = data,
      error: (err) => {
        this.storageService.clearUser();
        this.router.navigateByUrl("/login");
      },
      complete: () => {

        // Setto i dati del cliente nel form
        this.formCliente.setValue({
          email: this.cliente?.email,
          nome: this.cliente?.nome,
          cognome: this.cliente?.cognome,
          data_nascita: this.cliente?.data_nascita,
          luogo_nascita: this.cliente?.luogo_nascita,
          password: null,
          confirm_password: null,
          codice_fiscale: this.cliente?.codice_fiscale,
          telefono: this.cliente?.telefono,
          newsletter: this.cliente?.newsletter
        })
      }
    })
  }

  onElimina() {

    this.loading = true;

    // Elimino il cliente
    this.apiService.deleteCliente(this.user!.id).subscribe({
      next: (data) => { },
      error: (err) => this.onError(err.error.message, "error"),
      complete: () => {
        this.loading = false;
        this.storageService.clearUser();
        this.router.navigate(['/login'])
      }

    })

  }

  onError(message: string, type: "error" | "success" | "info" | "warning") {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = type;
    this.loading = false;
  }

  onSubmit() {

    // Creo l'oggetto per la richiesta dell'api
    let cliente = {
      email: this.formCliente.get('email')?.value,
      nome: this.formCliente.get('nome')?.value,
      cognome: this.formCliente.get('cognome')?.value,
      data_nascita: this.datePipe.transform(this.formCliente.get('data_nascita')?.value, 'yyyy-MM-dd'),
      luogo_nascita: this.formCliente.get('luogo_nascita')?.value,
      codice_fiscale: this.formCliente.get('codice_fiscale')?.value,
      telefono: this.formCliente.get('telefono')?.value,
      newsletter: this.formCliente.get('newsletter')?.value || false
    }

    // Creo l'oggetto per la richiesta dell'api
    let password = {
      password: this.formCliente.get('password')?.value || '',
      confirm_password: this.formCliente.get('confirm_password')?.value || '',
    }

    this.loading = true;

    // Aggiorno il cliente
    this.apiService.updateCliente(this.user!.id, cliente).subscribe({
      next: (data) => { },
      error: (err) => this.onError(err.error.message, "error"),
      complete: () => {

        // Se è stata inserita una password procedo
        if (password.password.length > 0) {

          // Aggiorno la password
          this.apiService.updatePasswordCliente(this.user!.id, password).subscribe({
            next: (data) => { },
            error: (err) => this.onError(err.error.message, "error"),
            complete: () => {

              this.onError('Modifiche salvate con successo!', "success")            
              this.storageService.setUser({ id: this.user!.id, nome: cliente.nome, cognome: cliente.cognome, email: cliente.email });

            }
          })
        } else {

          this.onError('Modifiche salvate con successo!', "success")
          this.storageService.setUser({ id: this.user!.id, nome: cliente.nome, cognome: cliente.cognome, email: cliente.email });

        }
      }
    })

  }

}
