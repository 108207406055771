<div class="bg-light contattaci d-flex align-items-center">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 d-none d-lg-flex bg-image"></div>

            <div class="col-lg-6 my-auto">
                <div class="form mx-auto p-lg-5 py-5 px-4">

                    <h3 class="display-4 fw-bold">Contattaci</h3>
                    <p class="text-muted mb-4">Contattaci inserendo i tuoi dati nel form qua sottostante
                        oppure
                        scrivici a <a href="mailto:webtv@7live.tv">webtv&#64;7live.tv</a>!
                    </p>
                    <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage"
                        [ngStyle]="{ marginBottom: '12px' }" *ngIf="alertVisible"></nz-alert>
                    <form [formGroup]="formContatti" (ngSubmit)="onLogin()">
                        <div class="form-data">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="forms-inputs mb-4">
                                        <span>*Nome e Cognome</span>
                                        <input nz-input autocomplete="off" type="text" formControlName="nominativo">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="forms-inputs mb-4">
                                        <span>*Email</span>
                                        <input nz-input autocomplete="off" type="email" formControlName="email">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="forms-inputs mb-4">
                                        <span>Azienda</span>
                                        <input nz-input autocomplete="off" type="text" formControlName="azienda">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="forms-inputs mb-4">
                                        <span>*Telefono</span>
                                        <input nz-input autocomplete="off" type="tel" formControlName="telefono">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="forms-inputs">
                                        <span>Messaggio</span>
                                        <textarea nz-input autocomplete="off" formControlName="messaggio" rows="6"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5 mb-3 text-center">
                                <button class="bottone m-0" nzType="primary" nzSize="large" nz-button type="submit" [nzLoading]="loading" [disabled]="!formContatti.valid">Contattaci</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
</div>