<div class="container">

  <!-- LOGO MOBILE -->
  <div class="d-flex justify-content-center py-md-3 py-5">
    <img class="d-lg-none d-block" src="../../../assets/images/Logo bianco.png" height="40px" alt="">
  </div>
  <!-- LOGO MOBILE -->

  <!-- BANNER E TITOLO -->
  <div class="row">
    <div class="banner" [style.background-image]="copertina_url" *ngIf="copertina_url.length >0 " [@fadeInOut]>
      <div class="sfumatura"></div>
    </div>
    <h3 class="text-white display-5 mt-3 testo fw-bold">Canale {{canale?.nome | titlecase}}</h3>
    <p class="testo">{{canale?.descrizione}}</p>
  </div>
  <!-- BANNER E TITOLO -->

  <!-- NO VIDEO -->
  <div class="row mt-3" *ngIf="ondemands.length <= 0 && !loading" [@fadeInOut]>
    <div class="col">
      <h3 class="mt-3 d-flex justify-content-center align-items-center">Questo canale non ha video</h3>
    </div>
  </div>
  <!-- NO VIDEO -->

  <!-- ONDEMANDS -->
  <div class="ondemands mb-5" *ngIf="ondemands.length > 0" [@fadeInOut]>

    <!-- EVIDENZA -->
    <div class="row my-3">
      <h3 class="text-white testo fw-bold">Video in evidenza</h3>
    </div>

    <div class="row">
      <div class="col">
        <app-galleria-video [ondemands]="ondemands.slice(0,10)"></app-galleria-video>
      </div>
    </div>
    <!-- EVIDENZA -->

    <!-- TUTTI -->
    <div class="row mt-5">
      <h3 class="text-white mb-3 testo fw-bold">Tutti i video</h3>
    </div>
    <div class="row g-3">
      <div class="col-md-4" *ngFor="let ondemand of ondemands.slice(0,ondemandLimit);">
        <app-video-thumbnail [ondemand]="ondemand" (click)="onOndemand(ondemand.id)"></app-video-thumbnail>
      </div>
    </div>
    <!-- TUTTI -->

    <!-- BOTTONI -->
    <div class="row mt-4">      
      
      <div [ngClass]="{'col-6 pe-0': ondemandLimit > 9, 'col': ondemandLimit <= 9}" *ngIf="ondemandLimit < ondemands.length" class="ciao">
        <nz-divider class="text-white ondemand-divider" [nzOrientation]="ondemandLimit <= 9 ? 'center' : 'right'" [nzText]="text">
          <ng-template #text>
            <div class="text-white button-divider" (click)="moreOndemands(6)">
              <span class="align-middle" nz-icon nzType="down" nzTheme="outline"></span>
            </div>          
          </ng-template>
        </nz-divider>
      </div>
      <div [ngClass]="{'col-6 ps-0': ondemandLimit > 9 && ondemandLimit < ondemands.length, 'col': ondemandLimit > ondemands.length}" *ngIf="ondemandLimit > 9">
        <nz-divider class="text-white ondemand-divider" [nzOrientation]="ondemandLimit > ondemands.length ? 'center' : 'left'" [nzText]="text">
          <ng-template #text>
            <div class="text-white button-divider" (click)="moreOndemands(-6)">
              <span class="align-middle" nz-icon nzType="up" nzTheme="outline"></span>
            </div>          
          </ng-template>
        </nz-divider>
      </div>

    </div>
    <!-- BOTTONI -->
  </div>
  <!-- ONDEMANDS -->
</div>