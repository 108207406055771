<div class="row">
    <div class="col-lg-8 pe-lg-0">
        <div id="player" [class.hover]="video.paused" class="player d-flex" style="position: relative; z-index: 1; height: 100%;">
            <div style="background-image: url('../../../assets/images/sfondi/nosignal.jpg'); background-size: cover; height: 100%; width: 100%; justify-content: center" class="play d-flex align-items-center flex-column" *ngIf="lives.length <= 0 && !loading" [@fadeInOut]>                
                <img src="../../../assets/images/logo.png" class="loader" alt="">
                <h3 class="mt-2 text-white text-center controlli">In attesa di Seven Live Web TV...</h3>                
            </div>          
            <div style="background-image: url('../../../assets/images/sfondi/nosignal.jpg'); background-size: cover; height: 100%; width: 100%; justify-content: center" class="play d-flex align-items-center flex-column" *ngIf="loading" [@fadeInOut]>            
                <div class="spinner-border text-light" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
            <div class="controls" id="controls" *ngIf="controlli" [@fadeInOut]>
                <div class="row">
                    <div class="col">
                        <h5 class="my-0 p-0 ms-3 mt-2 text-white fw-light testo canale">{{currentVideo.canale}}</h5>
                        <h3 class="mb-0 p-0 ms-3 mt-2 text-white testo crop" [attr.title]="currentVideo.titolo">{{currentVideo.titolo}}</h3>        
                    </div>
                </div>
                <div class="fondo">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="sx d-flex align-items-center">
                            <div class="stamp">
                                <span class="badge bg-danger">LIVE</span>
                            </div>
                            <div class="d-flex align-items-center ms-3">
                                <i class="bi fs-4" [class.bi-play-fill]="video.paused" [class.bi-pause-fill]="!video.paused" (click)="togglePause()"></i>
                            </div>
                            <div class="volume d-flex align-items-center">
                                <span class="ms-3 me-2"><i class="bi fs-4" (click)="onMute()" [class.bi-volume-mute-fill]="video.muted" [class.bi-volume-up-fill]="!video.muted"></i></span>
                                <div class="volume_wrapper d-flex align-items-center position-relative" style="width: 100px">
                                    <input class="volume_slider" type="range" min="0" max="100" (input)="onVolume($event)" (change)="onVolume($event)" [value]="volume">
                                    <div class="progress" [style.width]="volume + '%'"></div>
                                    <div class="back"></div>
                                </div>
                            </div>
                        </div>
                        <div class="dx d-flex align-items-center">
                            <div class="tasti d-flex align-items-center">
                                <span class="ms-3 me-1"><i class="bi fs-5" [ngClass]="{'bi-fullscreen':!isFScreen,'bi-fullscreen-exit':isFScreen}" (click)="onFullScreen()"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <video class="live-full" [muted]="true" #videoPlayer id="video" [poster]="currentVideo.poster" [controls]="false" preload="auto" [loop]="true" [autoplay]="true" playsinline (contextmenu)="onRightClick($event)" >
                <source id="videoSource" type="video/mp4" />
            </video>
        </div>

    </div>
    <div class="col-lg-4 ps-0 lista d-none d-lg-block">
        <ul class="player-list">
            <p>In evidenza:</p>
            <div *ngIf="!carica" [@fadeInOut]>
                <li *ngFor="let live of lives;" (click)="onVideoChange(live)" [ngClass]="{'selected': live.selected}">
                    <div class="row">
                        <div class="col-md-4 my-auto">
                            <img #icona [src]="live.icona ? url + 'immagine/' + live.icona : '../../../assets/images/Logo bianco.png'" (error)="icona.src = '../../../assets/images/Logo bianco.png'" class="watermark" alt="Icona">
                        </div>
                        <div class="col-md-8 my-auto" [attr.title]="live.canale">
                            {{ live.canale }}                        
                        </div>
                    </div>
                </li>
                <li *ngIf="lives.length <= 0" [@fadeInOut]>Nessun live presente</li>
            </div>            
            <li *ngIf="carica" class="text-center">
                <app-video-loading></app-video-loading>
            </li>
            
        </ul>
    </div>
</div>
<div class="row d-block d-lg-none">
    <div class="col">
        <div class="lives-list" *ngIf="lives.length > 0">
            <div class="playerlist-icon" *ngFor="let live of lives" (click)="onVideoChange(live)" [ngClass]="{'selected-mobile': live.selected}">
                <img #icona_mobile [src]="live.icona ? url + 'immagine/' + live.icona : '../../../assets/images/Logo bianco.png'" (error)="icona_mobile.src = '../../../assets/images/Logo bianco.png'" class="watermark" alt="Icona">
            </div>
        </div>
    </div>
</div>