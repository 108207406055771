import { Component, Input, OnInit } from '@angular/core';
import { Canale } from 'src/app/shared/interfacce.model';

@Component({
  selector: 'app-galleria-canali',
  templateUrl: './galleria-canali.component.html',
  styleUrls: ['./galleria-canali.component.css']
})
export class GalleriaCanaliComponent implements OnInit {
  
  @Input() canali: Canale[] = [];
  @Input() evidenza: boolean = false;

  constructor() {}

  ngOnInit(): void {
    
  }

}
