<div class="box rounded" [class.d-none]="dismissed || cookies">
    <div class="x position-absolute" (click)="dismiss()">
        <i class="bi bi-x"></i>
    </div>
  <div class="container p-4">
    <div class="row">
      <h5 class="text-white">Abbiamo a cuore la tua privacy</h5>
      <p class="mb-0">Usiamo i cookies per migliorare la tua esperienza.<br> Cliccando "Accetta", consenti dei cookies
        secondo la nostra <a class="text-white" routerLink="/privacy">Privacy policy.</a></p>
    </div>
    <div class="row gap-1 mt-4">
      <div class="p-0 col-12 col-md">
        <div class="d-block btn btn-light rounded-0" (click)="accept('all')">Accetta tutto</div>
      </div>
      <div class="p-0 col-12 col-md">
        <div class="d-block btn btn-light rounded-0" (click)="accept('basic')">Solo essenziali</div>
      </div>
      <div class="p-0 col-12 col-md-12">
        <div class="d-block btn btn-outline-light rounded-0" (click)="decline()">Rifiuta</div>
      </div>
    </div>
  </div>
</div>
